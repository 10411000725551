import { createSelector } from '@reduxjs/toolkit';
import { arrayUtil } from '../../util/arrayUtil';
import { rankUtil } from '../../util/rankUtil';
import { rpgUtil } from '../../util/rpgUtil';
import { abilitySelector } from '../ability';
import { characterSelector } from '../character';

import skillClass from './skillClass.json';

const selectClassSkillCount = createSelector(
    [characterSelector.characterClass],
    (characterClass) => skillClass[characterClass] || 12
);

const selectLevelSkillCount = createSelector(
    [characterSelector.characterClass],
    (characterClass) =>
        characterClass === 'rogue'
            ? arrayUtil.arrayOfInt(19, 2, 1)
            : arrayUtil.arrayOfInt(9, 3, 2)
);

const selectSkill = (state) => {
    return state.sheet.present.skill;
};

const getSkillList = (skillArr) =>
    skillArr.map((skill) => skill.id).filter(arrayUtil.filterUnique);

const selectRankArr = createSelector([selectSkill], (skills) => {
    const rankArr = Object.values(skills).map((skill) => ({
        type: 'extra',
        level: 1,
        rank: 1,
        key: '',
        ...skill,
    }));

    return rankArr;
});

const selectRankMap = createSelector([selectRankArr], (rankArr) =>
    rankUtil.selectRankMap(getSkillList(rankArr))(rankArr)
);

const selectRanks = createSelector([selectRankMap], rankUtil.selectRanks);

const selectErrors = createSelector([selectRankMap], rankUtil.selectErrors);

const selectCurrentRank = createSelector(
    [characterSelector.level, selectRankMap],
    rankUtil.selectCurrentRank(true)
);

const selectSkillFinalValue = createSelector(
    [
        selectCurrentRank,
        characterSelector.potency,
        abilitySelector.modifierArray,
    ],
    (ranks, potency, modifiers) => {
        // const finalMap = {};
        const lores = Object.entries(ranks)
            .filter(([id]) => id && !rpgUtil.skills.includes(id))
            .map(([lore]) => lore)
            .filter(arrayUtil.filterUnique)
            .map((lore) => [lore, 'int']);

        return rankUtil.selectFinalValue(rpgUtil.skillAndAbility.concat(lores))(
            ranks,
            potency,
            modifiers
        );
    }
);

export const skillSelector = {
    skills: selectSkill,
    ranks: selectRanks,
    currentRanks: selectCurrentRank,
    classSkillCount: selectClassSkillCount,
    levelSkillCount: selectLevelSkillCount,
    finalValues: selectSkillFinalValue,
    errors: selectErrors,
};

// const selectLores = createSelector(selectSkill, (skill) => skill.lores);

// const _calcProfByLevel = (skillId, profArr, level = 1, levelMap = {}) => {
//     const prevProf = level === 1 ? 0 : levelMap[level - 1];
//     const bestAtLevel = profArr
//         .filter((skill) => skill.level === level && skill.id === skillId)
//         .reduce(
//             (prev, curr) => Math.max(prev?.prof || 0, curr?.prof || 0, 0),
//             prevProf
//         );
//     levelMap[level] = bestAtLevel;

//     if (level === 20) return levelMap;
//     return calcProfByLevel(skillId, profArr, level + 1, levelMap);
// };

// const _calcProfMap = (skill) => {
//     const profArr = [
//         ...skill.classProf,
//         skill.intProf,
//         ...skill.levelX,
//         ...skill.extra,
//         ...skill.lores,
//     ].map((s) => ({ level: 1, prof: 1, ...s }));
//     const skillProfArr = rpgUtil.skills
//         .concat(skill.lores.map((s) => s.id))
//         .map((skillId) => [skillId, calcProfByLevel(skillId, profArr)]);

//     return arrayUtil.tuple2Obj(skillProfArr);
// };

// const maxProf = (skillId) =>
//     createSelector([selectSkill, characterSelector.level], (skill, level) => {
//         let best = 0;
//         const profIf = ({ id, prof }) => {
//             if (id === skillId) best = Math.max(best, prof);
//         };

//         skill.classProf.forEach(profIf);
//         skill.intProf.forEach(profIf);
//         skill.extra.forEach(profIf);

//         skill.levelX.filter((_, i) => i < level).forEach(profIf);

//         return getMaxRankByLevel(best, level);
//     });

// const getBaseData = (label) => (prof, potency) => ({
//     label: capitalize(label),
//     proficiency: prof * 2,
//     potency: prof > 0 ? potency : 0,
//     baseValue: prof > 0 ? prof * 2 + potency : 0,
// });

// const getFinalData = (modifierLabel) => (baseData, modifier) => ({
//     ...baseData,
//     modifierLabel,
//     modifier,
//     finalValue: baseData.baseValue + modifier,
// });

// const makeSkillBaseData = (skillKey) => () =>
//     createSelector(
//         [maxProf(skillKey), characterSelector.potency],
//         getBaseData(skillKey)
//     );

// const makeSkillFinalData =
//     ([skillKey, abilityKey], baseDataSelector) =>
//     () =>
//         createSelector(
//             [baseDataSelector(), abilitySelector[abilityKey + 'Modifier']],
//             getFinalData(abilityKey)
//         );

// const selectors = {};

// rpgUtil.skillAndAbility.forEach((skillArr) => {
//     const skillId = skillArr[0];
//     const baseDataSelector = makeSkillBaseData(skillId);
//     const finalDataSelector = makeSkillFinalData(skillArr, baseDataSelector);

//     selectors[skillId + 'Base'] = baseDataSelector();
//     selectors[skillArr[0] + 'Final'] = finalDataSelector();
// });

// selectors.loresBase = createSelector(
//     [
//         selectLores,
//         abilitySelector.modifierArray,
//         characterSelector.level,
//         characterSelector.potency,
//     ],
//     (lores, modifierArray, level, potency) => {
//         const loreList = [];
//         lores.forEach((l) => {
//             const baseDataValue = getBaseData(l.id)(
//                 getMaxRankByLevel(l.prof, level),
//                 potency
//             );
//             const finalDataValue = getFinalData(l.key)(
//                 baseDataValue,
//                 modifierArray[rpgUtil.abilityKey2ArrayIndex(l.key)]
//             );
//             loreList.push(finalDataValue);
//         });
//         return loreList;
//     }
// );

// selectors.profMap = createSelector([selectSkill], calcProfMap);

// export const skillSelector = selectors;
