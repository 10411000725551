import { createSlice } from '@reduxjs/toolkit';
import { stringUtil } from '../../util/stringUtil';
import { characterAction } from '../character';
import { sheetActions } from '../sheet/sheetActions';

import acClass from '../../data/class.json';

const initialState = {
    unarmoredClassLevel: [1, 0, 0, 0],
    lightClassLevel: [0, 0, 0, 0],
    mediumClassLevel: [0, 0, 0, 0],
    heavyClassLevel: [0, 0, 0, 0],
    worn: {
        itemBonus: 0,
        dexCap: 10,
        armorPenalty: 0,
        speedPenalty: 0,
        strReq: 0,
        type: 'unarmored',
        name: '',
    },
    extra: {},
};

const acSlice = createSlice({
    name: 'ac',
    initialState,
    reducers: {
        unarmoredClassLevel: (state, action) => {
            state.unarmoredClassLevel = action.payload;
        },
        lightClassLevel: (state, action) => {
            state.lightClassLevel = action.payload;
        },
        mediumClassLevel: (state, action) => {
            state.mediumClassLevel = action.payload;
        },
        heavyClassLevel: (state, action) => {
            state.heavyClassLevel = action.payload;
        },
        add: (state, action) => {
            const ac = action.payload.ac;
            if (!ac.slotId && !ac.extraId) ac.extraId = stringUtil.uuidv4();

            const slotId = action.payload.slotId || `ac|${ac.extraId || 0}`;
            state.extra[slotId] = ac;
        },
        remove: (state, action) => {
            delete state.extra[action.payload];
        },
        worn: (state, action) => {
            state.worn = action.payload;
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING AC', action.payload.sheet.ac);

            return action.payload.sheet.ac;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
        [characterAction.class]: (state, action) => {
            const newState = { ...initialState };

            if (action.payload in acClass) {
                const {
                    unarmored = [],
                    light = [],
                    medium = [],
                    heavy = [],
                } = acClass[action.payload];
                
                newState.unarmoredClassLevel = unarmored;
                newState.lightClassLevel = light;
                newState.mediumClassLevel = medium;
                newState.heavyClassLevel = heavy;
            }

            return newState;
        },
    },
});

export const acAction = acSlice.actions;
export const acReducer = acSlice.reducer;
