/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Ability } from '../ability';
import { Feat } from '../feat';
import { HP } from '../hp';
import { Save } from '../save';
import { Skill } from '../skill';
import './tabs.css';
import './sheet.css';
import { Badge } from './Badge';
import { AC } from '../ac';

export const Sheet = () => {
    const [tabs, panels] = makeTapsAndPanels(
        makeAbilityTab(),
        makeDefenseTab(),

        makeSkillTab(),
        makeFeatTab()

        // makeWeaponTab(),
        // makeDefenseTab()
        // makeSpelltab()
    );

    return (
        <Tabs>
            <TabList>{tabs}</TabList>

            {panels}
        </Tabs>
    );
};

const makeTapsAndPanels = (...args) => {
    const tabs = [];
    const panels = [];
    args.forEach(([tab, panel, className], i) => {
        tabs.push(<Tab key={i}>{tab}</Tab>);
        panels.push(
            <TabPanel key={i} className={'tab-panel-content ' + className}>
                {panel}
            </TabPanel>
        );
    });
    return [tabs, panels];
};

const makeAbilityTab = () => {
    return [
        <FontAwesomeIcon icon='fa-solid fa-person' />,
        <>
            <h1>Character</h1>
            <Ability />
            <HP />
            <Badge />
        </>,
        'ability-tab',
    ];
};

const makeFeatTab = () => {
    return [
        <FontAwesomeIcon icon='fa-solid fa-star-half-stroke' />,
        <>
            <h1>Feats</h1>
            <Feat />
        </>,
        'feat-tab',
    ];
};

const makeSkillTab = () => {
    return [
        <FontAwesomeIcon icon='fa-solid fa-compass' />,
        <>
            <h1>Skills</h1>
            <Skill />
        </>,
        'skill-tab',
    ];
};

const makeWeaponTab = () => {
    return [
        <FontAwesomeIcon icon='fa-solid fa-crosshairs' />,
        <></>,
        'weapon-tab',
    ];
};

const makeDefenseTab = () => {
    return [
        <FontAwesomeIcon icon='fa-solid fa-chess-rook' />,
        <>
            <Save />
            <AC />
        </>,
        'defense-tab',
    ];
};

const makeSpelltab = () => {
    return [
        <FontAwesomeIcon icon='fa-solid fa-hat-wizard' />,
        <></>,
        'spell-tab',
    ];
};
