import { capitalize } from 'lodash';
import { Proficency } from '.';
import '../../css/rank-table.css';

const RankRow = ({ type = 'skill', id, value }) => {
    const { label, proficiency, potency, modifierLabel, modifier, finalValue } =
        value;

    const title =
        type === 'lore'
            ? id.split(' ').map(capitalize).join(' ')
            : capitalize(id);

    return (
        <div className='rank-row'>
            <span>
                {title} ({modifierLabel})
            </span>
            <span>{finalValue.signed()}</span>
            <span>=</span>
            <Proficency label={label} value={proficiency} />
            <span>+</span>
            <span className={`modifier ${type}`}>{modifier}</span>
            <span>+</span>
            <span>{potency}</span>
        </div>
    );
};

export default RankRow;
