import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { acSelector } from './index';
import './ac.css';
import '../../css/defense-tab.css'
import { RankRow } from '../common';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACLevel } from './ACLevel';
import { Worn } from './Worn';

export const AC = (props) => {
    const finalValues = useSelector(acSelector.finalValues)


    return (
        <div className='ac'>
            <EditModal />
            <div className='rank-table'>
                <div className='rank-row header'>
                    <span><FontAwesomeIcon icon='fa-solid fa-shirt' />Armor Class</span>
                    <span>Final</span>
                    <span></span>
                    <span>Prof</span>
                    <span></span>
                    <span>Mod<sup>*</sup></span>
                    <span></span>
                    <span>Pot</span>
                </div>
                <RankRow
                    type='ac'
                    id='unarmored'
                    value={finalValues.unarmored}
                />
                <RankRow
                    type='ac'
                    id='light'
                    value={finalValues.light}
                />
                <RankRow
                    type='ac'
                    id='medium'
                    value={finalValues.medium}
                />
                <RankRow
                    type='ac'
                    id='heavy'
                    value={finalValues.heavy}
                />
                <p>*Worn armor will apply an item bonus and add your limited dex modifier.</p>
            </div>
            <Worn />
        </div>
    );
};

const EditModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className='save-edit'>
            <button onClick={openModal}>
                <FontAwesomeIcon icon='fa-solid fa-pen-to-square' /> Edit Armor
            </button>
            <Modal
                className='modal-modal'
                overlayClassName='modal-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <ACLevel />
            </Modal>
        </div>
    );
};
