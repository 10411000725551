import { createSelector } from '@reduxjs/toolkit';

const selectCharacter = (state) => {
    return state.sheet.present.character;
};

const name = createSelector(selectCharacter, (character) => character.name);
const background = createSelector(
    selectCharacter,
    (character) => character.background
);
const ancestry = createSelector(
    selectCharacter,
    (character) => character.ancestry
);
const heritage = createSelector(
    selectCharacter,
    (character) => character.heritage
);
const culture = createSelector(
    selectCharacter,
    (character) => character.culture
);
const devotion = createSelector(
    selectCharacter,
    (character) => character.devotion
);

const level = createSelector(selectCharacter, (character) => character.level);

const characterClass = createSelector(
    selectCharacter,
    (character) => character.class
);

const potency = createSelector(level, (level) => {
    if (level < 4) return 0;
    if (level < 10) return 1;
    if (level < 16) return 2;

    return 3;
});

const damageDice = createSelector(level, (level) => {
    if (level < 4) return 1;
    if (level < 12) return 2;
    if (level < 19) return 3;

    return 4;
});

export const characterSelector = {
    name,
    level,
    potency,
    damageDice,
    characterClass,
    ancestry,
    background,
    heritage,
    culture,
    devotion,
};
