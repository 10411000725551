import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { abilityAction, abilitySelector } from './index';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip';

import './ability.css';
import { arrayUtil } from '../../util/arrayUtil';
import { rpgUtil } from '../../util/rpgUtil';

export const Ability = (props) => {
    const scores = useSelector(abilitySelector.scoreArray);

    const modifiers = useSelector(abilitySelector.modifierArray);

    const scoreTable = rpgUtil.abilityScores.map((score, i) => {
        const labelStr = rpgUtil.abilityKey2FullName(score);
        return [
            <div key={i + 'id'}>{labelStr}</div>,
            <div
                key={i + 'value'}
                id={labelStr + '-tooltip'}
                className={scores[i].err ? 'error' : ''}
                data-tooltip-content={scores[i].err}
            >
                {scores[i].score}
            </div>,
            <div key={i + 'modifier'}>{modifiers[i].signed()}</div>,
            scores[i].err ? (
                <Tooltip key={i + 'error'} anchorId={labelStr + '-tooltip'} />
            ) : null,
        ];
    });

    return (
        <div className='ability'>
            <span className='tab-heading'>
                <EditModal />
                <h2>Ability Scores</h2>
            </span>

            <div className='score-table'>{scoreTable}</div>
        </div>
    );
};

const EditModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className='ability-edit'>
            <button onClick={openModal}>
                <FontAwesomeIcon icon='fa-solid fa-pen-to-square' />
            </button>
            <Modal
                className='modal-modal'
                overlayClassName='modal-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <Edit />
            </Modal>
        </div>
    );
};

const Edit = () => {
    const keyAbility = useSelector(abilitySelector.keyAbility);
    const ancestryBoost = useSelector(abilitySelector.ancestry);
    const ancestryFlaw = useSelector(abilitySelector.ancestryFlaw);
    const background = useSelector(abilitySelector.background);
    const level1 = useSelector(abilitySelector.level1);

    return (
        <div>
            <h1>Edit Ability Scores</h1>
            <div className='ability-rows'>
                <AbilityRow
                    label='Ancestry Boost'
                    max={ancestryFlaw.length ? 3 : 2}
                    current={ancestryBoost}
                    action={abilityAction.ancestryBoost}
                    description={`Select two different abilities to boost. One boost is predetermined by your ancestry. The other is free, and can be any other ability.
                    If you ancestry has a flaw, you instead choose the two predetermeined boosts and one free.`}
                />
                <AbilityRow
                    type='flaw'
                    label='Ancestry Flaw'
                    max={1}
                    current={ancestryFlaw}
                    action={abilityAction.ancestryFlaw}
                    description={`If your ancestry provides a flaw, select it here. Otherwise, ignore this entry. Your free boost from ancestry can be the same as your ancestry's flaw.`}
                />

                <AbilityRow
                    label='Background'
                    max={2}
                    current={background}
                    action={abilityAction.backgroundBoost}
                    description={`Select two different abilities to boost. One boost is limited to the abilities listed in your background. The other is free, and can be any other ability.`}
                />

                <AbilityRow
                    label='Key Ability'
                    max={1}
                    current={keyAbility}
                    action={abilityAction.keyAbility}
                    description={`Select the key ability for your class. If you class has multiple options, choose one ability.`}
                />

                <AbilityRow
                    label='Level 1'
                    max={4}
                    current={level1}
                    action={abilityAction.level1Boost}
                    description={`Select four different abilities to boost.`}
                />
            </div>
            <LevelXs />
        </div>
    );
};

const AbilityRow = ({
    type = 'boost',
    label,
    max = 1,
    current = ['str'],
    allowed = ['str', 'dex', 'con', 'int', 'wis', 'cha'],
    action,
    description,
}) => {
    const dispatch = useDispatch();
    const isError = max > current.length && type !== 'flaw';

    const handleClick = (id) => () => {
        if (current.includes(id))
            dispatch(action(current.filter((stat) => stat !== id)));
        else
            dispatch(
                action(
                    [id, ...current]
                        .filter(arrayUtil.filterUnique)
                        .slice(0, max)
                )
            );
    };

    const makeStatButton = (id, label) => {
        const isCurrent = current.includes(id);
        return (
            <button
                key={id}
                className={(isCurrent ? 'active ' : '') + type}
                onClick={handleClick(id)}
                disabled={!allowed.includes(id)}
            >
                {id}
            </button>
        );
    };

    return (
        <div className={'ability-row' + (isError ? ' error' : '')}>
            <h2>{label}</h2>
            <div className='ability-row-description'>{description}</div>
            <div className='ability-row-grid'>
                {makeStatButton('str', 'Strength')}
                {makeStatButton('dex', 'Dexterity')}
                {makeStatButton('con', 'Constitution')}
                {makeStatButton('int', 'Intelligence')}
                {makeStatButton('wis', 'Wisdom')}
                {makeStatButton('cha', 'Charisma')}
            </div>
        </div>
    );
};

const LevelXs = () => {
    const dispatch = useDispatch();
    const levelXMap = useSelector(abilitySelector.levelX);

    const levelElements = arrayUtil.arrayOfInt(19, 0).map((level) => {
        const id = levelXMap[level] || '';
        return (
            <div key={level} className='ability-level'>
                <label htmlFor={`ability-level-${level}`}>
                    level {level + 2}
                </label>
                <select
                    name={`ability-level-${level}`}
                    value={id}
                    onChange={(e) =>
                        dispatch(
                            abilityAction.levelXBoost({
                                level,
                                boost: e.target.value,
                            })
                        )
                    }
                >
                    <option value=''>{'—'}</option>
                    {rpgUtil.abilityScores.map((c) => (
                        <option key={level + c} value={c}>
                            {c}
                        </option>
                    ))}
                </select>
            </div>
        );
    });

    return (
        <div className='ability-levels'>
            {levelElements}{' '}
            <button
                className='clear-level-boost'
                onClick={() => dispatch(abilityAction.clearLevelBoost())}
            >
                Clear Level Up Boosts
            </button>
        </div>
    );
};
