import React, { useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { rpgUtil } from '../../util/rpgUtil';
import { SkillLevel, skillSelector } from './index';
import '../../css/rank-table.css'
import { Proficency } from '../common';

export const Skill = (props) => {
    const finalValues = useSelector(skillSelector.finalValues);
    const loreValues = Object.entries(finalValues).filter(
        ([id]) => !rpgUtil.skills.includes(id)
    );

    return (
        <div className='skill'>
            <div className='rank-table'>
                <div className='rank-row header'>
                    <span>Skill</span>
                    <span>Final</span>
                    <span></span>
                    <span>Prof</span>
                    <span></span>
                    <span>Mod</span>
                    <span></span>
                    <span>Pot</span>
                </div>
                {rpgUtil.skills.map((id) => (
                    <SkillRow key={id} id={id} skill={finalValues[id]} />
                ))}
                {loreValues.map(([id, value]) => (
                    <SkillRow key={id} type='lore' id={id} skill={value} />
                ))}
                
            </div>

            <EditModal />
        </div>
    );
};

const SkillRow = ({ type = 'skill', id, skill }) => {
    const { label, proficiency, potency, modifierLabel, modifier, finalValue } =
        skill;

    const title =
        type === 'lore'
            ? id.split(' ').map(capitalize).join(' ')
            : capitalize(id);

    return (
        <div className='rank-row'>
            <span>
                {title} ({modifierLabel})
            </span>
            <span>{finalValue.signed()}</span>
            <span>=</span>
            <Proficency label={label} value={proficiency} />
            <span>+</span>
            <span>{modifier}</span>
            <span>+</span>
            <span>{potency}</span>
        </div>
    );
};



const EditModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className='skill-edit'>
            <button onClick={openModal}>
                <FontAwesomeIcon icon='fa-solid fa-pen-to-square' /> Level Plan
            </button>
            <Modal
                className='modal-modal'
                overlayClassName='modal-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <SkillLevel />
            </Modal>
        </div>
    );
};
