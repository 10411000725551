const isSubset = (primeArray, subArray) => {
    if (!Array.isArray(primeArray) || !Array.isArray(subArray)) return false;
    // console.log(`Looking for ${subArray} in ${primeArray}`);
    for (const sub of subArray) {
        if (!primeArray.includes(sub)) return false;
    }
    return true;
};

const notSubset = (primeArray, subArray) => {
    if (!Array.isArray(primeArray) || !Array.isArray(subArray)) return false;
    for (const sub of subArray) {
        if (primeArray.includes(sub)) return false;
    }
    return true;
};

const arrayOfInt = (count = 20, startingValue = 1, increment = 1) =>
    Array(count)
        .fill(0)
        .map((_, i) => startingValue + increment * i);

const arrayOf = (count = 20, initialValue) => Array(count).fill(initialValue);

const filterUnique = (curr, i, arr) => arr.indexOf(curr) === i;

const filterUniqueByKey = (key) => (curr, i, arr) =>
    arr.findIndex((n) => n[key] === curr[key]) === i;

const array2Obj = (array, key = 'id') =>
    array.reduce((obj, item) => {
        if (key in item) obj[item[key]] = item;
        return obj;
    }, {});

const tuple2Obj = (array) =>
    array.reduce((obj, [key, value]) => ({ [key]: value, ...obj }), {});

export const arrayUtil = {
    isSubset,
    notSubset,
    arrayOfInt,
    arrayOf,
    filterUnique,
    filterUniqueByKey,
    array2Obj,
    tuple2Obj
};
