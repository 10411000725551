import { configureStore } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import undoable from 'redux-undo';
import { sheetReducer } from '../features/sheet';
import { uuidReducer, versionNumber, versionReducer } from '../features/uuid';

const storageKey = 'sheet' + versionNumber;

const saveState = (state) => {
    try {
        if (typeof window !== 'undefined') {
            const {
                uuid,
                version,
                sheet: { present },
            } = state;
            const dataStr = JSON.stringify({ uuid, version, sheet: present });
            window.localStorage.setItem(storageKey, dataStr);
            console.log('NEW STATE', JSON.parse(dataStr));
        }
    } catch (err) {
        console.error(err);
    }
};

const loadState = () => {
    try {
        if (typeof window !== 'undefined') {
            const str = window.localStorage.getItem(storageKey);
            if (str === null) return undefined;
            const { uuid, version, sheet } = JSON.parse(str);

            if (uuid) return { uuid, version, sheet: sheet };

            return undefined;
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const versionMiddleware = (store) => (next) => (action) => {
    // console.log(action, store.getState());
    // TODO: check version of store and action payload. Update as needed.

    if (store.getState().version !== versionNumber)
        console.log(
            `Sheet is version ${
                store.getState().version
            }. Please write the update code UwU`
        );

    return next(action);
};

export const store = configureStore({
    preloadedState: loadState(),
    reducer: {
        uuid: uuidReducer,
        version: versionReducer,
        sheet: undoable(sheetReducer),
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(versionMiddleware),
});

store.subscribe(
    throttle(() => {
        // console.log('saving to local storage');
        // console.log('NEW STATE', store.getState());
        saveState(store.getState());
    }, 1000)
);
