import { createSlice } from '@reduxjs/toolkit';
import { sheetActions } from '../sheet/sheetActions';

const initialState = '1.2.0';

const versionSlice = createSlice({
    name: 'version_id',
    initialState,
    reducers: {
        updateVersion: (state, action) => {
            state = action.payload;
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            return action.payload.version;
        },
    },
});

export const versionAction = versionSlice.actions;
export const versionReducer = versionSlice.reducer;
export const versionNumber = initialState;
