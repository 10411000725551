import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import { rpgUtil } from '../../util/rpgUtil';
import { characterAction, characterSelector } from './index';

import './character.css';

export const Character = () => {
    return (
        <div className='character'>
            <NameClassLevel />
            <AncestryHeritage />
            <BackgroundCultureDevotion />
        </div>
    );
};

const NameClassLevel = () => {
    const name = useSelector(characterSelector.name);
    const level = useSelector(characterSelector.level);
    const characterClass = useSelector(characterSelector.characterClass);

    const dispatch = useDispatch();

    return (
        <div className='name-class-level'>
            <label htmlFor='nameInput'>name</label>
            <label htmlFor='classInput'>class</label>
            <label htmlFor='levelInput'>level</label>

            <input
                className={`name ${name||'none'}`}
                name='nameInput'
                value={name}
                onChange={(e) => {
                    dispatch(characterAction.name(e.target.value));
                }}
            />

            <select
                className='class'
                name='classInput'
                value={characterClass}
                onChange={(e) =>
                    dispatch(characterAction.class(e.target.value))
                }
            >
                <option value=''>none</option>
                {rpgUtil.characterClasses.map((c) => (
                    <option key={c} value={c}>
                        {c}
                    </option>
                ))}
            </select>

            <select
                className='level'
                name='levelInput'
                value={level}
                onChange={(e) =>
                    dispatch(characterAction.level(e.target.value))
                }
            >
                {arrayUtil.arrayOfInt(20).map((lvl) => (
                    <option key={lvl} value={lvl}>
                        {lvl}
                    </option>
                ))}
            </select>
        </div>
    );
};

const AncestryHeritage = () => {
    const ancestry = useSelector(characterSelector.ancestry);
    const heritage = useSelector(characterSelector.heritage);

    const dispatch = useDispatch();

    return (
        <div className='ancestry-heritage'>
            <label htmlFor='ancestryInput'>ancestry</label>
            <label htmlFor='heritageInput'>heritage</label>

            <select
                className='ancestry'
                name='ancestryInput'
                value={ancestry}
                onChange={(e) =>
                    dispatch(characterAction.ancestry(e.target.value))
                }
            >
                <option value=''>none</option>
                {rpgUtil.ancestry.map((c) => (
                    <option key={c} value={c}>
                        {c}
                    </option>
                ))}
            </select>

            <input
                className='heritage'
                name='heritageInput'
                value={heritage}
                onChange={(e) => {
                    dispatch(characterAction.heritage(e.target.value));
                }}
                onFocus={(event) => event.target.select()}
            />
        </div>
    );
};

const BackgroundCultureDevotion = () => {
    const background = useSelector(characterSelector.background);
    const culture = useSelector(characterSelector.culture);
    const devotion = useSelector(characterSelector.devotion);

    const dispatch = useDispatch();

    return (
        <div className='background-culture-devotion'>
            <label className='background' htmlFor='backgroundInput'>
                background
            </label>
            <label className='culture' htmlFor='cultureInput'>
                culture
            </label>
            <label className='devotion' htmlFor='devotionInput'>
                devotion
            </label>

            <input
                className='background'
                name='backgroundInput'
                value={background}
                onChange={(e) => {
                    dispatch(characterAction.background(e.target.value));
                }}
                onFocus={(event) => event.target.select()}
            />

            <input
                className='culture'
                name='cultureInput'
                value={culture}
                onChange={(e) => {
                    dispatch(characterAction.culture(e.target.value));
                }}
                onFocus={(event) => event.target.select()}
            />

            <input
                className='devotion'
                name='devotionInput'
                value={devotion}
                onChange={(e) => {
                    dispatch(characterAction.devotion(e.target.value));
                }}
                onFocus={(event) => event.target.select()}
            />
        </div>
    );
};
