import { createSlice } from '@reduxjs/toolkit';
import { stringUtil } from '../../util/stringUtil';
import { characterAction } from '../character';
import { sheetActions } from '../sheet/sheetActions';

import saveClass from '../../data/class.json';

const initialState = {
    percClassLevel: [1, 0, 0, 0],
    fortClassLevel: [1, 0, 0, 0],
    refClassLevel: [1, 0, 0, 0],
    willClassLevel: [1, 0, 0, 0],
    extra: {},
};

// const verifyRankLevel = ([t, e, m, l]) => {
//     if (t > e) e = t;
//     if (t > m) m = t;
//     if (t > l) l = t;

//     if (e > m) m = e;
//     if (e > l) l = e;

//     if (m > l) l = m;

//     return [t, e, m, l];
// };

const saveSlice = createSlice({
    name: 'defense',
    initialState,
    reducers: {
        percClassLevel: (state, action) => {
            state.percClassLevel = action.payload;
        },
        fortClassLevel: (state, action) => {
            state.fortClassLevel = action.payload;
        },
        refClassLevel: (state, action) => {
            state.refClassLevel = action.payload;
        },
        willClassLevel: (state, action) => {
            state.willClassLevel = action.payload;
        },
        add: (state, action) => {
            const save = action.payload.save;
            if (!save.slotId && !save.extraId)
                save.extraId = stringUtil.uuidv4();

            const slotId = action.payload.slotId || `save|${save.extraId || 0}`;
            state.extra[slotId] = save;
        },
        remove: (state, action) => {
            delete state.extra[action.payload];
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING SAVES', action.payload.sheet.save);

            return action.payload.sheet.save;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
        [characterAction.class]: (state, action) => {
            const newState = { ...initialState };

            if (action.payload in saveClass) {
                const {
                    fort = [],
                    ref = [],
                    will = [],
                    perc = [],
                } = saveClass[action.payload];
                

                newState.fortClassLevel = fort;
                newState.refClassLevel = ref;
                newState.willClassLevel = will;
                newState.percClassLevel = perc;
            }

            return newState;
        },
    },
});

export const saveAction = saveSlice.actions;
export const saveReducer = saveSlice.reducer;
