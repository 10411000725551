import { createSelector } from '@reduxjs/toolkit';
import { characterSelector } from '../character';
import { abilitySelector } from '../ability';
import { rankUtil } from '../../util/rankUtil';
import { rpgUtil } from '../../util/rpgUtil';

const selectAC = (state) => {
    return state.sheet.present.ac;
};

const selectWorn = createSelector([selectAC], (ac) => ac.worn);

const selectRankArr = createSelector([selectAC], (ac) => {
    const rankArr = Object.values(ac.extra)
        .concat(
            // unarmored
            ac.unarmoredClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'dex',
                id: 'unarmored',
            }))
        )
        .concat(
            // light
            ac.lightClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'dex',
                id: 'light',
            }))
        )
        .concat(
            // medium
            ac.mediumClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'dex',
                id: 'medium',
            }))
        )
        .concat(
            // heavy
            ac.heavyClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'dex',
                id: 'heavy',
            }))
        );

    return rankArr;
});

const selectRankMap = createSelector(
    [selectRankArr],
    rankUtil.selectRankMap(rpgUtil.armors)
);

const selectRanks = createSelector([selectRankMap], rankUtil.selectRanks);

const selectErrors = createSelector([selectRankMap], rankUtil.selectErrors);

const selectCurrentRank = createSelector(
    [characterSelector.level, selectRankMap],
    rankUtil.selectCurrentRank(false)
);

const selectFinalValue = createSelector(
    [
        selectCurrentRank,
        characterSelector.potency,
        abilitySelector.modifierArray,
    ],
    rankUtil.selectFinalValue(rpgUtil.armorsAndAbility, true)
);

const selectUnarmoredAC = createSelector([selectFinalValue], (finalValues) => {
    const wFinal = finalValues['unarmored'];
    return {
        ...wFinal,
        itemBonus: 0,
        dexCap: 10,
        armorPenalty: 0,
        speedPenalty: 0,
        strReq: 0,
        type: 'unarmored',
        finalAC: wFinal.baseValue + wFinal.modifier,
    };
});

const selectWornAC = createSelector(
    [selectFinalValue, selectWorn, selectUnarmoredAC],
    (finalValues, worn, unarmored) => {
        const { type, itemBonus, dexCap } = worn;
        const wFinal = finalValues[type];

        if (wFinal)
            return {
                ...wFinal,
                ...worn,
                finalAC:
                    10 +
                        wFinal.baseValue +
                        Math.min(wFinal.modifier, dexCap) +
                        Number(itemBonus) || 0,
            };

        return unarmored;
    }
);

const selectArmorPenalty = createSelector(
    [selectWorn, abilitySelector.strScore],
    ({ armorPenalty, strReq }, { score: strScore }) => {
        return strReq > strScore ? Number(armorPenalty) : 0;
    }
);

const selectSpeedPenalty = createSelector(
    [selectWorn, abilitySelector.strScore],
    ({ speedPenalty, strReq }, { score: strScore }) =>
        Number(strReq) > strScore
            ? Number(speedPenalty)
            : Math.min(0, Number(speedPenalty) + 5)
);

export const acSelector = {
    ac: selectAC,
    ranks: selectRanks,
    currentRanks: selectCurrentRank,
    finalValues: selectFinalValue,
    errors: selectErrors,
    wornAC: selectWornAC,
    unarmoredAC: selectUnarmoredAC,
    armorPenalty: selectArmorPenalty,
    speedPenalty: selectSpeedPenalty,
};
