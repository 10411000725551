import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { acSelector } from '../ac/acSelector';
import { Proficency } from '../common';
import { saveSelector } from '../save';

export const Badge = () => {
    const saves = useSelector(saveSelector.finalValues);
    const ac = useSelector(acSelector.wornAC);
    const armorPenalty = useSelector(acSelector.armorPenalty);
    const speedPenalty = useSelector(acSelector.speedPenalty);

    return (
        <div className='badges'>
            <div className='col'>
                <div className='ac badge'>
                    <h2 className='title'>
                        <FontAwesomeIcon icon='fa-solid fa-shirt' />
                        AC Worn
                    </h2>

                    <div className='row'>
                        <span className='label'>
                            {ac.name || ''} ({ac.type})
                        </span>
                        <Proficency value={ac.proficiency} />
                        <span className='final-value'>
                            <span>{ac.finalAC} AC</span>
                        </span>
                        <span className='minor-label'>
                            <div>Armor Penalty: {armorPenalty.signed(true)}</div>
                            <div>Speed Penalty: {speedPenalty.signed(true)}</div>
                        </span>
                    </div>
                </div>

                <div className='save badge'>
                    <h2 className='title'>
                        <FontAwesomeIcon icon='fa-solid fa-shield-halved' />
                        Saving Throws
                    </h2>

                    <div className='row'>
                        <span className='label'>Fortitude</span>
                        <Proficency value={saves.fortitude.proficiency} />
                        <span className='final-value'>
                            <span>{saves.fortitude.finalValue.signed()}</span>
                            <span>/</span>
                            <span>DC {saves.fortitude.finalValue + 10}</span>
                        </span>
                    </div>
                    <div className='row'>
                        <span className='label'>Reflex</span>
                        <Proficency value={saves.reflex.proficiency} />
                        <span className='final-value'>
                            <span>{saves.reflex.finalValue.signed()}</span>
                            <span>/</span>
                            <span>DC {saves.reflex.finalValue + 10}</span>
                        </span>
                    </div>
                    <div className='row'>
                        <span className='label'>Will</span>
                        <Proficency value={saves.will.proficiency} />
                        <span className='final-value'>
                            <span>{saves.will.finalValue.signed()}</span>
                            <span>/</span>
                            <span>DC {saves.will.finalValue + 10}</span>
                        </span>
                    </div>
                </div>
            </div>

            <div className='col'>
                <div className='perception badge'>
                    <h2 className='title'>
                        <FontAwesomeIcon icon='fa-solid fa-eye' />
                        Perception
                    </h2>

                    <div className='row'>
                        <span className='label'>Perception</span>
                        <Proficency value={saves.perception.proficiency} />
                        <span className='final-value'>
                            <span>{saves.perception.finalValue.signed()}</span>
                            <span>/</span>
                            <span>DC {saves.perception.finalValue + 10}</span>
                        </span>
                    </div>
                </div>

                {/* <div className='combat badge'>
                    <h2 className='title'>
                        <FontAwesomeIcon icon='fa-solid fa-hand-fist' /> Offense
                    </h2>

                    <div className='row'>
                        <span className='label'>[type-label] Simple</span>
                        <Proficency value={saves.perception.proficiency} />
                        <span className='final-value'>
                            <span>
                                {saves.perception.finalValue.signed()}
                                <sub>brute</sub>
                            </span>
                            <span>/</span>
                            <span>
                                {saves.perception.finalValue.signed()}
                                <sub>agile</sub>
                            </span>
                        </span>
                    </div>
                </div> */}

                {/* <div className='magic badge'>
                    <h2 className='title'>
                        <FontAwesomeIcon icon='fa-solid fa-scroll' /> Spells
                    </h2>

                    <div className='row'>
                        <span className='label'>[type-label] Arcane</span>
                        <Proficency value={saves.perception.proficiency} />
                        <span className='final-value'>
                            <span>{saves.perception.finalValue.signed()}</span>
                            <span>/</span>
                            <span>DC {saves.perception.finalValue + 10}</span>
                        </span>
                    </div>
                </div> */}
            </div>
        </div>
    );
};
