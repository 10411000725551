import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import { rpgUtil } from '../../util/rpgUtil';
import { acSelector } from './acSelector';
import { acAction } from './acSlice';

export const ACLevel = () => {
    const ac = useSelector(acSelector.ac);

    return (
        <div className='defense-level'>
            <div className='class-defense'>
                <h2>Proficency from Class</h2>
                <p>
                    Your class's armor proficiencies are automatically loaded
                    when selecting your class. Only edit these values if your class grants proficencies from the sub-class. Just in case, consult with the GM before modifiying.
                </p>

                <table>
                    <thead className='ac'>
                        <tr>
                            <th>Armor</th>
                            <th>Trained</th>
                            <th>Expert</th>
                            <th>Master</th>
                            <th>Legendary</th>
                        </tr>
                    </thead>

                    <tbody className='ac'>
                        <ArmorTableRow
                            id='unarmored'
                            proficiency={ac.unarmoredClassLevel}
                        />
                        <ArmorTableRow
                            id='light'
                            proficiency={ac.lightClassLevel}
                        />
                        <ArmorTableRow
                            id='medium'
                            proficiency={ac.mediumClassLevel}
                        />
                        <ArmorTableRow
                            id='heavy'
                            proficiency={ac.heavyClassLevel}
                        />
                    </tbody>
                </table>
            </div>
            <CustomBonus />
        </div>
    );
};

const ArmorTableRow = ({ id, proficiency }) => {
    const dispatch = useDispatch();
    let action;
    switch (id) {
        case 'unarmored':
            action = acAction.unarmoredClassLevel;
            break;
        case 'light':
            action = acAction.lightClassLevel;
            break;
        case 'medium':
            action = acAction.mediumClassLevel;
            break;
        case 'heavy':
        default:
            action = acAction.heavyClassLevel;
            break;
    }

    const [trainedLevel, expertLevel, masterLevel, legendaryLevel] =
        proficiency || [];

    const handleTrainedChange = (newLevel) =>
        dispatch(action([newLevel, expertLevel, masterLevel, legendaryLevel]));

    const handleExpertChange = (newLevel) =>
        dispatch(action([trainedLevel, newLevel, masterLevel, legendaryLevel]));

    const handleMasterChange = (newLevel) =>
        dispatch(action([trainedLevel, expertLevel, newLevel, legendaryLevel]));

    const handleLegendaryChange = (newLevel) =>
        dispatch(action([trainedLevel, expertLevel, masterLevel, newLevel]));

    return (
        <tr>
            <td>{capitalize(id)}</td>
            <td>
                <LevelSelect
                    value={trainedLevel}
                    onChange={handleTrainedChange}
                />
            </td>
            <td>
                <LevelSelect
                    value={expertLevel}
                    onChange={handleExpertChange}
                />
            </td>
            <td>
                <LevelSelect
                    value={masterLevel}
                    onChange={handleMasterChange}
                />
            </td>
            <td>
                <LevelSelect
                    value={legendaryLevel}
                    onChange={handleLegendaryChange}
                />
            </td>
        </tr>
    );
};

const LevelSelect = ({ value, onChange }) => {
    const handleChange = (e) =>
        typeof onChange === 'function' && onChange(e.target.value);

    return (
        <input
            value={value}
            onChange={handleChange}
            onFocus={(event) => event.target.select()}
        />
    );
};

const CustomBonus = () => {
    const acs = useSelector(acSelector.ac);

    const dispatch = useDispatch();

    const handleAddExtra = () =>
        dispatch(
            acAction.add({
                ac: { id: '', level: 1, rank: 2, type: 'extra' },
            })
        );

    return (
        <div className='extra-defense'>
            <h2>Extra ACs</h2>
            <p>
                You can gain extra proficiency by taking certain feats. Record them here.
            </p>
            <div className='extra-defense-control'>
                <button onClick={handleAddExtra}>
                    Add Extra AC Proficency
                </button>
            </div>
            <div className='defense-level-row'>
                {Object.values(acs.extra).map((eAC, i) => (
                    <ExtraAC key={i} ac={eAC} />
                ))}
            </div>
        </div>
    );
};

const ExtraAC = ({ ac }) => {
    const dispatch = useDispatch();
    const slotId = `ac|${ac.extraId}`;

    const handleLevelSelect = (e) =>
        dispatch(
            acAction.add({
                ac: {
                    ...ac,
                    level: e.target.value || 1,
                },
            })
        );

    const handleACSelect = (e) => {
        if (e.target.value)
            dispatch(
                acAction.add({
                    ac: {
                        ...ac,
                        id: e.target.value,
                    },
                })
            );
        else dispatch(acAction.remove(slotId));
    };

    const handleRankSelect = (e) =>
        dispatch(
            acAction.add({
                ac: {
                    ...ac,
                    rank: e.target.value,
                },
            })
        );
    const handleRemove = () => {
        dispatch(acAction.remove(slotId));
    };

    return (
        <div className='rank-cell extra'>
            <label>Extra</label>
            <div className='split'>
                <select value={ac.level} onChange={handleLevelSelect}>
                    {arrayUtil.arrayOfInt().map((lvl) => (
                        <option key={lvl} value={lvl}>
                            Level {lvl}
                        </option>
                    ))}
                </select>
                <button onClick={handleRemove}>
                    <FontAwesomeIcon icon='fa-solid fa-trash-can' />
                </button>
            </div>
            <select
                id={slotId}
                value={ac?.id || ''}
                onChange={handleACSelect}
            >
                <option value={''}>none</option>
                {rpgUtil.armors.map((id) => (
                    <option key={id} value={id}>
                        {id}
                    </option>
                ))}
            </select>
            <select
                value={ac?.rank || 0}
                onChange={handleRankSelect}
                disabled={!ac?.id}
            >
                <option value={1}>trained</option>
                <option value={2}>expert</option>
                <option value={3}>master</option>
                <option value={4}>legendary</option>
            </select>
        </div>
    );
};
