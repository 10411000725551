import { createSlice } from '@reduxjs/toolkit';
import { sheetActions } from '../sheet/sheetActions';

const initialState = {
    keyAbility: [],
    ancestryBoost: [],
    ancestryFlaw: [],
    backgroundBoost: [],
    level1: [],
    levelX: [],
    extra: [],
    flaw: [],
};

const abilitySlice = createSlice({
    name: 'ability',
    initialState,
    reducers: {
        keyAbility: (state, action) => {
            state.keyAbility = action.payload;
        },
        ancestryBoost: (state, action) => {
            state.ancestryBoost = action.payload;
        },
        ancestryFlaw: (state, action) => {
            state.ancestryFlaw = action.payload;

            if (action.payload.length === 0)
                state.ancestryBoost = state.ancestryBoost.slice(0, 2);
        },
        backgroundBoost: (state, action) => {
            state.backgroundBoost = action.payload;
        },
        level1Boost: (state, action) => {
            state.level1 = action.payload;
        },
        levelXBoost: (state, action) => {
            state.levelX[action.payload.level] = action.payload.boost;
        },
        extraBoost: (state, action) => {
            state.extra = action.payload;
        },

        clearLevelBoost: (state) => {
            state.levelX = [];
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING ABILITY', action.payload.sheet.ability);

            return action.payload.sheet.ability;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
    },
});

export const abilityAction = abilitySlice.actions;
export const abilityReducer = abilitySlice.reducer;
