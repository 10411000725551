import { createSelector } from '@reduxjs/toolkit';
import { characterSelector } from '../character';

import featSlotModel from './featSlots.json';
import featClass from '../../data/class.json';

const applySlotId = ({ type, level, id }, index, arr) => {
    const beforeArr = arr.slice(0, index);
    const beforeCount =
        beforeArr.filter((s) => s.type === type && s.level === level).length ||
        0;

    return { slotId: `${type}|${level}.${beforeCount}`, type, level, id };
};

const selectFeat = (state) => state.sheet.present.feat;

const selectClassSpecificSlots = createSelector(
    [characterSelector.characterClass],
    (characterClass) => {
        const classSlots = featClass[characterClass].feats;

        if (classSlots) return classSlots;

        return [];
    }
);

const selectKnownFeatSlots = createSelector(
    [selectClassSpecificSlots],
    (classSpecificArr) => {
        const defaultArr = featSlotModel.default;

        return defaultArr.concat(classSpecificArr).map(applySlotId);
    }
);

const selectSlotMap = createSelector(
    [selectFeat, selectKnownFeatSlots],
    (userMap, knownArr) => {
        const slotMap = {};
        Object.entries(userMap).forEach(
            ([slotId, userItem]) => (slotMap[slotId] = { slotId, ...userItem })
        );
        knownArr.forEach((known) => {
            const userSelectedFeat = userMap[known.slotId];
            slotMap[known.slotId] = userSelectedFeat
                ? { ...known, ...userSelectedFeat }
                : known;
        });

        return slotMap;
    }
);

export const featSelector = {
    slots: selectSlotMap,
};
