import { useDispatch, useSelector } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import { acSelector } from './acSelector';
import { acAction } from './acSlice';

export const Worn = () => {
    const {
        itemBonus = 0,
        dexCap = 10,
        armorPenalty = 0,
        speedPenalty = 0,
        strReq = 0,
        type = 'unarmored',
        name = 'clothes',
        finalAC,
    } = useSelector(acSelector.wornAC);

    const dispatch = useDispatch();

    const retro = {
        name,
        itemBonus,
        dexCap,
        armorPenalty,
        speedPenalty,
        strReq,
        type,
    };

    const handleNameChange = (event) =>
        dispatch(acAction.worn({ ...retro, name: event.target.value }));

    const handleItemBonus = (event) =>
        dispatch(acAction.worn({ ...retro, itemBonus: event.target.value }));

    const handleDexCap = (event) =>
        dispatch(acAction.worn({ ...retro, dexCap: event.target.value }));

    const handleArmorPenalty = (event) =>
        dispatch(acAction.worn({ ...retro, armorPenalty: event.target.value }));

    const handleSpeedPenalty = (event) =>
        dispatch(acAction.worn({ ...retro, speedPenalty: event.target.value }));

    const handleStrReq = (event) =>
        dispatch(acAction.worn({ ...retro, strReq: event.target.value }));

    const handleType = (event) =>
        dispatch(acAction.worn({ ...retro, type: event.target.value }));

    return (
        <div>
            <div className='worn'>
                <label id='worn-name-label' htmlFor='worn-name'>
                    Name
                </label>
                <label id='worn-item-label' htmlFor='worn-item'>
                    Item Bonus
                </label>
                <label id='worn-dex-label' htmlFor='worn-dex'>
                    Dex Cap
                </label>
                <label id='worn-apen-label' htmlFor='worn-apen'>
                    Skill Penalty
                </label>
                <label id='worn-spen-label' htmlFor='worn-spen'>
                    Speed Penalty
                </label>
                <label id='worn-str-label' htmlFor='worn-str'>
                    Str Req.
                </label>
                <label id='worn-type-label' htmlFor='worn-type'>
                    Type
                </label>
                <input
                    id='worn-name'
                    value={name}
                    onChange={handleNameChange}
                />
                <select id='worn-type' value={type} onChange={handleType}>
                    <option value=''>–</option>
                    <option value='light'>Light</option>
                    <option value='medium'>Medium</option>
                    <option value='heavy'>Heavy</option>
                    <option value='unarmored'>Unarmored</option>
                </select>
                <select
                    id='worn-item'
                    value={itemBonus}
                    onChange={handleItemBonus}
                >
                    {arrayUtil.arrayOfInt(6, 0, 1).map((i) => (
                        <option key={i} value={i}>
                            +{i}
                        </option>
                    ))}
                </select>
                <select id='worn-dex' value={dexCap} onChange={handleDexCap}>
                    {arrayUtil.arrayOfInt(7, 0, 1).map((i) => (
                        <option key={i} value={i}>
                            +{i}
                        </option>
                    ))}
                    <option value=''>unlimited</option>
                </select>
                <select id='worn-str' value={strReq} onChange={handleStrReq}>
                    <option value=''>none</option>
                    {arrayUtil.arrayOfInt(6, 12, 2).map((i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>
                <select
                    id='worn-apen'
                    value={armorPenalty}
                    onChange={handleArmorPenalty}
                >
                    <option value={0}>none</option>
                    {arrayUtil.arrayOfInt(3, -1, -1).map((i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>

                <select
                    id='worn-spen'
                    value={speedPenalty}
                    onChange={handleSpeedPenalty}
                >
                    <option value={0}>none</option>
                    {arrayUtil.arrayOfInt(2, -5, -5).map((i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>

                <div id='worn-value'>current AC {finalAC}</div>
            </div>
        </div>
    );
};
