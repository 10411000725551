const abilityKey2ArrayIndex = (key) => {
    switch (key) {
        case 'str':
            return 0;
        case 'dex':
            return 1;
        case 'con':
            return 2;
        case 'int':
            return 3;
        case 'wis':
            return 4;
        case 'cha':
            return 5;
        default:
            return 0;
    }
};

const abilityKey2FullName = (key) => {
    switch (key) {
        case 'str':
            return 'Strength';
        case 'dex':
            return 'Dexterity';
        case 'con':
            return 'Consitution';
        case 'int':
            return 'Intelligence';
        case 'wis':
            return 'Wisdom';
        case 'cha':
            return 'Charisma';
        default:
            return 0;
    }
};

const abilityScores = ['str', 'dex', 'con', 'int', 'wis', 'cha'];

const characterClasses = [
    'alchemist',
    'barbarian',
    'bard',
    'champion',
    'cleric',
    'druid',
    'fighter',
    'gunslinger',
    'inventor',
    'investigator',
    'magus',
    'monk',
    'oracle',
    'psychic',
    'ranger',
    'rogue',
    'sorcerer',
    'summoner',
    'swashbuckler',
    'thaumaturge',
    'witch',
    'wizard',
];

const ancestry = [
    'dwarf',
    'elf',
    'gnome',
    'half-orc',
    'halfling',
    'human',
    'khoravar',
    'bugbear',
    'changeling',
    'goblin',
    'hobgoblin',
    'kobold',
    'orc',
    'shifter',
    'catfolk',
    'dragonborn',
    'gnoll',
    'kalashtar',
    'lizardfolk',
    'warforged',
    'aasimar',
    'automaton',
    'fleshwarp',
    'sprite',
    'tiefling',
];

const skills = [
    ['acrobatics', 'dex'],
    ['arcana', 'int'],
    ['athletics', 'str'],
    ['crafting', 'int'],
    ['deception', 'cha'],
    ['diplomacy', 'cha'],
    ['intimidation', 'cha'],
    ['medicine', 'wis'],
    ['nature', 'wis'],
    ['occultism', 'int'],
    ['performance', 'cha'],
    ['religion', 'wis'],
    ['society', 'int'],
    ['stealth', 'dex'],
    ['survival', 'wis'],
    ['thievery', 'dex'],
];

const saves = [
    ['fortitude', 'con'],
    ['reflex', 'dex'],
    ['will', 'wis'],
    ['perception', 'wis'],
];

const armors = [
    ['unarmored', 'dex'],
    ['light', 'dex'],
    ['medium', 'dex'],
    ['heavy', 'dex'],
];

const getMaxRankByLevel = (rank, level) => {
    let best = rank;
    if (level <= 20) best = Math.min(4, best);
    if (level <= 14) best = Math.min(3, best);
    if (level <= 6) best = Math.min(2, best);
    if (level === 1) best = Math.min(1, best);

    return best;
};

export const rpgUtil = {
    abilityKey2ArrayIndex,
    characterClasses,
    ancestry,
    abilityScores,
    abilityKey2FullName,
    skillAndAbility: skills,
    savesAndAbility: saves,
    armorsAndAbility:armors,
    skills: skills.map(([s]) => s),
    saves: saves.map(([s]) => s),
    armors: armors.map(([s]) => s),
    maxSkillRankByLevel: getMaxRankByLevel,
};
