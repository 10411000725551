import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { VaultSelectModal } from './controls/vault';
import { Character } from './features/character';
import { Sheet } from './features/sheet';

import 'react-tooltip/dist/react-tooltip.css';
import './css/app.css';

function App() {
    return (
        <div className='App'>
            <AppControl />
            <Character />
            <Sheet />
        </div>
    );
}

const AppControl = () => {
    const dispatch = useDispatch();

    return (
        <div className='control'>
            <div className='undo-redo'>
                <button onClick={() => dispatch(ActionCreators.undo())}>
                    <FontAwesomeIcon icon='fa-solid fa-undo' />
                </button>
                <button onClick={() => dispatch(ActionCreators.redo())}>
                    <FontAwesomeIcon icon='fa-solid fa-redo' />
                </button>
            </div>

            <button
                onClick={() => {
                    window.localStorage.removeItem('sheet');
                    window.location.reload();
                }}
            >
                Reset
            </button>

            <VaultSelectModal />
        </div>
    );
};

export default App;
