import { createSelector } from '@reduxjs/toolkit';
import { abilitySelector } from '../ability';
import { characterSelector } from '../character';

import { numUtil } from '../../util/numUtil';
import hpClass2 from '../../data/class.json'

const selectHP = (state) => state.sheet.present.hp;

const selectAncestry = createSelector([selectHP], (hp) => hp.ancestry);
const selectTemp = createSelector([selectHP], (hp) => hp.temp);
const selectPerLevel = createSelector([selectHP], (hp) => hp.perLevel);
const selectClassPerLevel = createSelector(
    [characterSelector.characterClass],
    (characterClass) => hpClass2[characterClass]?.hp || 0
);

const selectTotalPerLevel = createSelector(
    [selectClassPerLevel, selectPerLevel, abilitySelector.conModifier],
    (classPerLevel, bonusPerLevel, conMod) => {
        return (
            numUtil.sane(classPerLevel) +
            numUtil.sane(bonusPerLevel) +
            numUtil.sane(conMod)
        );
    }
);

const selectHPTotal = createSelector(
    [selectAncestry, selectTotalPerLevel, characterSelector.level],
    (ancestry, perLevel, level) => {
        return (
            numUtil.sane(ancestry) +
            numUtil.sane(level) * numUtil.sane(perLevel)
        );
    }
);
const selectFormula = createSelector(
    [
        selectHPTotal,
        selectAncestry,
        selectClassPerLevel,
        selectPerLevel,
        abilitySelector.conModifier,
        characterSelector.level,
    ],
    (total, ancestry, classPerLevel, bonusPerLevel, conMod, level) => ({
        total,
        ancestry,
        classPerLevel,
        bonusPerLevel,
        conMod,
        level,
    })
);

const selectCurrent = createSelector([selectHP, selectHPTotal], (hp, total) =>
    Math.max(0, Math.min(total, numUtil.sane(hp.current)))
);

export const hpSelector = {
    ancestry: selectAncestry,
    classPerLevel: selectClassPerLevel,
    perLevel: selectPerLevel,
    current: selectCurrent,
    hpTotal: selectHPTotal,
    formula: selectFormula,
    temp:selectTemp,
};
