import { createSelector } from '@reduxjs/toolkit';
import { characterSelector } from '../character';
import { abilitySelector } from '../ability';
import { rankUtil } from '../../util/rankUtil';
import { rpgUtil } from '../../util/rpgUtil';

const selectSave = (state) => {
    return state.sheet.present.save;
};

const selectRankArr = createSelector([selectSave], (save) => {
    // Convert class level array into rank objects
    const rankArr = Object.values(save.extra)
        .concat(
            // Fortitude
            save.fortClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'con',
                id: 'fortitude',
            }))
        )
        .concat(
            // Reflex
            save.refClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'dex',
                id: 'reflex',
            }))
        )
        .concat(
            // Will
            save.willClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'wis',
                id: 'will',
            }))
        )
        .concat(
            // Perception
            save.percClassLevel.map((level, index) => ({
                type: 'class',
                level: Number(level) || 0,
                rank: index + 1,
                key: 'wis',
                id: 'perception',
            }))
        );

    // Remove rank objects that should be ignored (outside of level bounds)
    return rankArr//.filter((save) => save.level <= 20 && save.level >= 1);
});

const selectRankMap = createSelector(
    [selectRankArr],
    rankUtil.selectRankMap(rpgUtil.saves)
);

const selectRanks = createSelector([selectRankMap], rankUtil.selectRanks);

const selectErrors = createSelector([selectRankMap], rankUtil.selectErrors);

const selectCurrentRank = createSelector(
    [characterSelector.level, selectRankMap],
    rankUtil.selectCurrentRank(false)
);

const selectSaveFinalValue = createSelector(
    [
        selectCurrentRank,
        characterSelector.potency,
        abilitySelector.modifierArray,
    ],
    rankUtil.selectFinalValue(rpgUtil.savesAndAbility)
);

export const saveSelector = {
    saves: selectSave,
    ranks: selectRanks,
    currentRanks: selectCurrentRank,
    finalValues: selectSaveFinalValue,
    errors: selectErrors,
};
