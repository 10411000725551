import { createSlice } from '@reduxjs/toolkit';
import { sheetActions } from '../sheet/sheetActions';

const initialState = {
    name: '',
    level: 1,
    ancestry: '',
    heritage: '',
    background: '',
    culture: '',
    devotion: '',
    class: '',
    size: 'medium',
    landSpeed: 5,
    flySpeed: 0,
    burrowSpeed: 0,
};

const characterSlice = createSlice({
    name: 'character',
    initialState,
    reducers: {
        name: (state, action) => {
            state.name = String(action.payload);
        },
        level: (state, action) => {
            state.level = Number(action.payload);
        },
        class: (state, action) => {
            state.class = String(action.payload);
        },
        ancestry: (state, action) => {
            state.ancestry = String(action.payload);
        },
        heritage: (state, action) => {
            state.heritage = String(action.payload);
        },
        background: (state, action) => {
            state.background = String(action.payload);
        },
        culture: (state, action) => {
            state.culture = String(action.payload);
        },
        devotion: (state, action) => {
            state.devotion = String(action.payload);
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING CHARACTER', action.payload.sheet.character);
            return action.payload.sheet.character;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
    },
});

export const characterAction = characterSlice.actions;
export const characterReducer = characterSlice.reducer;
