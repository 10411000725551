import { combineReducers } from '@reduxjs/toolkit';
import { abilityReducer } from '../ability';
import { acReducer } from '../ac';
import { characterReducer } from '../character';
import { featReducer } from '../feat';
import { hpReducer } from '../hp';
import { saveReducer } from '../save';
import { skillReducer } from '../skill';
import { weaponReducer } from '../weapon';

export const sheetReducer = combineReducers({
    character: characterReducer,
    ability: abilityReducer,
    skill: skillReducer,
    save: saveReducer,
    ac: acReducer,
    feat: featReducer,
    weapon: weaponReducer,
    hp: hpReducer,
});

export const sheetSelector = {
    present: ({ sheet: { present }, ...state }) => {
        return {
            sheet: present,
            ...state,
        };
    },
};

const sheet2String = (sheet) => {
    if (sheet.sheet) return sheet2String(sheet.sheet);

    const {
        character: { name, class: characterClass, level },
    } = sheet;

    return `[${characterClass} ${level}] --- ${name || 'UNNAME CHARACTER'}`;
};

const getName = (sheet) =>
    sheet.sheet
        ? getName(sheet.sheet)
        : sheet?.character?.name || 'UNNAMED CHARACTER';

const getClass = (sheet) =>
    sheet.sheet ? getClass(sheet.sheet) : sheet?.character?.class || '[CLASS]';

const getLevel = (sheet) =>
    sheet.sheet ? getLevel(sheet.sheet) : sheet?.character?.level || 0;

export const sheetUtil = {
    toString: sheet2String,
    getName: getName,
    getClass,
    getLevel,
    getClassLevel: (sheet) => getClass(sheet) + ' ' + getLevel(sheet),
};

export { Sheet } from './Sheet';

