import { arrayUtil } from './arrayUtil';
import { rpgUtil } from './rpgUtil';

const calcRankByLevel = (
    skillId,
    rankArr,
    intMod,
    level = 1,
    levelMap = {},
    errMap = {}
) => {
    const prevRank = level === 1 ? 0 : levelMap[level - 1];
    const bestAtLevelO = rankArr
        .filter((skill) => skill.id === skillId)
        .reduce((prevRank, skill) => {
            const currRank = Number(skill?.rank);
            const currLevel = Number(skill?.level);

            //skip ranking if not part of level
            if (currLevel !== level) return prevRank;

            // ERROR when rank appears twice
            if (prevRank === currRank)
                errMap[skillId + currRank + 'eq'] = {
                    id: skillId,
                    rank: prevRank,
                    type: 'eq',
                };

            //ERROR when new rank is smaller than previous
            if (currRank >= 1 && prevRank && prevRank > currRank)
                errMap[skillId + currRank + 'lt'] = {
                    id: skillId,
                    rank: prevRank,
                    type: 'lt',
                };

            // ERROR when jumping ranks
            if (
                currRank > prevRank + 1 &&
                currRank !== 5 &&
                skill.type !== 'extra' &&
                skill.type !== 'lore'
            )
                errMap[skillId + currRank + 'jp'] = {
                    id: skillId,
                    rank: currRank,
                    type: 'jp',
                };

            //skip ranking if not enough INT
            if (skill.type === 'int' && skill.intMod < intMod) return prevRank;

            return Math.max(prevRank || 0, currRank || 0, 0);
        }, prevRank);

    levelMap[level] = bestAtLevelO;

    if (level === 20) {
        return { proficiency: levelMap, errors: errMap };
    }
    return calcRankByLevel(
        skillId,
        rankArr,
        intMod,
        level + 1,
        levelMap,
        errMap
    );
};

const selectRankMap = (idArr) => (rankArr, intMod) => {
    const tuple = idArr.map((id) => [id, calcRankByLevel(id, rankArr, intMod)]);
    return arrayUtil.tuple2Obj(tuple);
};

const selectRanks = (rankMap) => {
    const ranks = {};
    Object.entries(rankMap).forEach(
        ([id, { proficiency }]) => (ranks[id] = proficiency)
    );

    return ranks;
};

const selectErrors = (rankMap) => {
    return Object.values(rankMap).flatMap(({ errors }) =>
        Object.values(errors)
    );
};

const selectCurrentRank =
    (isCappedByLevel = true) =>
    (level, rankMap) => {
        const atLevel = {};
        Object.entries(rankMap).forEach(
            ([skillId, { proficiency }]) =>
                (atLevel[skillId] = rpgUtil.maxSkillRankByLevel(
                    proficiency[level],
                    isCappedByLevel ? level : 20
                ))
        );


        return atLevel;
    };

const selectFinalValue =
    (skillAndAbilityArr, isArmor= false) => (ranks, potency, modifiers) => {
        const finalMap = {};
        const addFinalMap = ([skillId, modId]) => {
            const rank = ranks[skillId] || 0;
            const modifier = modifiers[rpgUtil.abilityKey2ArrayIndex(modId)];
            const proficiency = rank * 2;
            const baseValue = rank > 0 ? proficiency + potency : 0;
            const finalValue = baseValue + (!isArmor?modifier:0);

            finalMap[skillId] = {
                potency: rank ? potency : 0,
                proficiency,
                modifier,
                modifierLabel: modId,
                baseValue,
                finalValue,
            };
        };

        skillAndAbilityArr.forEach(addFinalMap);

        return finalMap;
    };

export const rankUtil = {
    calcRankByLevel,
    selectRankMap,
    selectRanks,
    selectErrors,
    selectCurrentRank,
    selectFinalValue,
};
