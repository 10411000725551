import { createSlice } from '@reduxjs/toolkit';
import { sheetActions } from '../sheet/sheetActions';

// const initialState = {
//     list: [
//         {
//             id: 'feat.skill.crb.alchemical-crafting',
//             slot: 'class|1.0',
//         },
//     ],
//     extraSlots: [],
// };

const initialState = {};

const featSlice = createSlice({
    name: 'feat',
    initialState,
    reducers: {
        add: (state, action) => {
            const feat = action.payload.feat;
            const slotId =
                action.payload.slotId ||
                `${feat.type}|${feat.level}.${feat.extraId}`;
            state[slotId] = feat;
        },
        remove: (state, action) => {
            delete state[action.payload];
        },
        updateNote: (state, action) => {
            // const { note, slot } = action.payload;
            // const feat2Change =
            //     state.list.find((f) => f.slot === slot) ||
            //     state.extraSlots.find((f) => f.slot === slot);
            // feat2Change.note = note;
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING FEAT', action.payload.sheet.feat);

            return action.payload.sheet.feat;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
    },
});

export const featAction = featSlice.actions;
export const featReducer = featSlice.reducer;
