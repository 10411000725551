import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import { useFetchArray } from '../../util/hook';
import { stringUtil } from '../../util/stringUtil';
import { characterSelector } from '../character';
import { FeatSelectModal } from './FeatModal';
import { featSelector } from './featSelector';
import { featAction } from './featSlice';

import './feat.css';

const featURL =
    'https://raw.githubusercontent.com/eberron94/dozen-dragons-wiki/main/data/converted/feat.json';

const featSortOrder = [
    'class',
    'sub-class',
    'ancestry',
    'background',
    'paragon',
    'general',
    'skill',
    'bonus',
    'extra',
];

const sortFeats = (a, b) => {
    const aIndex = featSortOrder.indexOf(a.type),
        bIndex = featSortOrder.indexOf(b.type);

    return aIndex - bIndex;
};

export const Feat = (props) => {
    const featSlots = useSelector(featSelector.slots);
    const currentLevel = useSelector(characterSelector.level);
    const [hideFuture, setHideFuture] = useState(true);
    const [dataList, tryFetchDataList] = useFetchArray(featURL, 'featData', []);
    const dispatch = useDispatch();

    const makeLevelCells = makeLevelRows(
        dispatch,
        featSlots,
        dataList,
        currentLevel,
        hideFuture
    );
    const levelRows = arrayUtil.arrayOfInt(20, 1).map(makeLevelCells);

    return (
        <div className='feat'>
            <div className='feat-controller'>
                <button onClick={() => setHideFuture(!hideFuture)}>
                    See all levels
                </button>
                <button onClick={() => tryFetchDataList()}>
                    Reload Feat Data
                </button>
            </div>
            <div className='feat-container'>{levelRows}</div>
        </div>
    );
};

const makeLevelRows =
    (dispatch, featSlots, dataList, currentLevel, hideFuture, hideSkill) =>
    (level) => {
        const handleExtraFeat = (id) => {
            dispatch(
                featAction.add({
                    feat: {
                        id,
                        note: '',
                        type: 'extra',
                        level,
                        extraId: stringUtil.uuidv4(),
                    },
                })
            );
        };

        const slotsAtLevel = Object.values(featSlots).filter(
            (slot) => slot.level === level
        );

        const cells = slotsAtLevel
            .map((slot) => ({
                ...slot,
                feat: dataList.find(({ id }) => id === slot.id) || undefined,
            }))
            .sort(sortFeats);

        let className = 'feat-row';
        if (currentLevel >= level) {
            className += ' active';
        } else {
            className += ' future';
            if (hideFuture) className += ' hidden';
        }

        return (
            <div key={level} className={className}>
                <div className='feat-row-level-label'>
                    <span>Level {level}</span>
                    <FeatSelectModal
                        level={level}
                        onFeatSelection={handleExtraFeat}
                    >
                        <FontAwesomeIcon icon='fa-solid fa-plus' />
                    </FeatSelectModal>
                </div>
                <div className='feat-row-slots'>
                    {cells.map((c, i) => (
                        <Cell key={i} {...c} />
                    ))}
                </div>
            </div>
        );
    };

const Cell = ({ slotId, type, level, id, feat, extraId }) => {
    const dispatch = useDispatch();

    const handleSelect = (selectedId) => {
        dispatch(
            featAction.add({
                slotId,
                feat: { id: selectedId, type, level, note: '' },
            })
        );
    };

    const handleRemove = () => {
        dispatch(featAction.remove(slotId));
    };

    let actionElem;

    if (feat) {
        actionElem = (
            <button onClick={handleRemove} disabled={type === 'bonus'}>
                <FontAwesomeIcon icon='fa-solid fa-trash-can' />
            </button>
        );
    } else {
        actionElem = (
            <FeatSelectModal
                type={type}
                level={level}
                onFeatSelection={handleSelect}
            >
                <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
            </FeatSelectModal>
        );
    }

    return (
        <div className={'feat-slot feat-slot-' + type}>
            <div className='feat-slot-type'>
                <span>{type.toUpperCase()}</span>
                <span>{level}</span>
            </div>
            <div className='feat-slot-display'>
                <div className='feat-slot-title'>
                    {feat?.name || feat?.title || id || `No Feat Selected`}
                </div>
                {actionElem}
            </div>
        </div>
    );
};
