import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { sheetSelector, sheetUtil } from '../../features/sheet';
import { sheetActions } from '../../features/sheet/sheetActions';

import { useLocalStorage } from '../../util/hook';
import { stringUtil } from '../../util/stringUtil';

export const VaultSelectModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className='vault'>
            <button onClick={openModal}>
                Manage Vault <FontAwesomeIcon icon='fa-solid fa-vault' />
            </button>
            <Modal
                className='modal-modal'
                overlayClassName='modal-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <Vault />
            </Modal>
        </div>
    );
};

const Vault = () => {
    const dispatch = useDispatch();
    const presentSheet = useSelector(sheetSelector.present);
    const [vault, setVault] = useLocalStorage('vault', []);

    const updateActiveSheet = (newActive) => {
        dispatch(sheetActions.loadSheet(newActive));
    };

    const handleLoadFromVault = (loadedVaultSheet) => () => {
        setVault(
            vault
                .filter((item) => item.uuid !== loadedVaultSheet.uuid)
                .concat([presentSheet])
        );
        updateActiveSheet(loadedVaultSheet);
    };

    const handleStoreCurrent = () => {
        setVault(vault.concat([duplicateSheet(presentSheet)]));
    };

    const handleDeleteFromVault = (clickedVaultSheet) => () => {
        setVault(
            vault.filter(
                (vaultSheet) => vaultSheet.uuid !== clickedVaultSheet.uuid
            )
        );
    };

    const vaultItems = vault.map((vaultSheet) => (
        <li key={vaultSheet.uuid} className='vault-character'>
            <div className='vault-character-name'>
                <span>{sheetUtil.getName(vaultSheet)}</span>
                <span>{sheetUtil.getClassLevel(vaultSheet)}</span>
            </div>
            <div className='vault-character-control'>
                <button onClick={handleLoadFromVault(vaultSheet)}>
                    <FontAwesomeIcon icon='fa-solid fa-file-import' />
                </button>
                <button onClick={handleDeleteFromVault(vaultSheet)}>
                    <FontAwesomeIcon icon='fa-solid fa-trash-can' />
                </button>
            </div>
        </li>
    ));

    return (
        <>
            <button onClick={handleStoreCurrent}>Store Current</button>
            <ul className='vault-characters'>{vaultItems}</ul>
        </>
    );
};

const duplicateSheet = (presentSheet) => {
    return { ...presentSheet, uuid: stringUtil.uuidv4() };
};
