import { createSelector } from '@reduxjs/toolkit';
import { characterSelector } from '../character';

const selectAbility = (state) => state.sheet.present.ability;

const selectKeyAbilityBoost = createSelector(
    [selectAbility],
    (ability) => ability.keyAbility
);
const selectAncestryBoost = createSelector(
    [selectAbility],
    (ability) => ability.ancestryBoost
);
const selectAncestryFlaw = createSelector(
    [selectAbility],
    (ability) => ability.ancestryFlaw
);
const selectBackgroundBoost = createSelector(
    [selectAbility],
    (ability) => ability.backgroundBoost
);
const selectLevel1Boost = createSelector(
    [selectAbility],
    (ability) => ability.level1
);

const selectLevelXBoosts = createSelector([selectAbility], (ability) => {
    const levelX = ability.levelX;
    const levelMap = {};
    levelX.forEach((id, i) => (levelMap[i] = id));

    return levelMap;
});

const countBoosts = (key) =>
    createSelector(
        [selectAbility, characterSelector.level],
        (ability, level) => {
            let count = 0;
            const boostIf = (b) => {
                if (b === key) count++;
            };
            const flawIf = (f) => {
                if (f === key) count--;
            };

            ability.keyAbility.forEach(boostIf);
            ability.ancestryBoost.forEach(boostIf);
            ability.ancestryFlaw.forEach(flawIf);
            ability.backgroundBoost.forEach(boostIf);
            ability.level1.forEach(boostIf);
            ability.extra.forEach(boostIf);
            ability.flaw.forEach(flawIf);

            ability.levelX.filter((_, i) => i < level).forEach(boostIf);

            return count;
        }
    );

const levelBoundBoost = (key) =>
    createSelector(
        [countBoosts(key), characterSelector.level],
        (count, level) => {
            if (level === 1 && count > 4)
                return {
                    boosts: 4,
                    err: `Ability cannot have more than 4 boosts at 1st level. You have ${count}.`,
                };
            else if (level <= 4 && count > 5)
                return {
                    boosts: 5,
                    err: `Ability cannot have more than 5 boosts before 5th level. You have ${count}.`,
                };
            else if (level <= 9 && count > 6)
                return {
                    boosts: 6,
                    err: `Ability cannot have more than 6 boosts before 10th level. You have ${count}.`,
                };
            else if (level <= 14 && count > 7)
                return {
                    boosts: 7,
                    err: `Ability cannot have more than 7 boosts before 15th level. You have ${count}.`,
                };
            else if (level <= 20 && count > 8)
                return {
                    boosts: 8,
                    err: `Ability cannot have more than 8 boosts. You have ${count}.`,
                };
            else
                return {
                    boosts: count,
                    err: '',
                };
        }
    );

const calcScoreFromBoost = ({ boosts, err }) => ({
    score: 10 + Math.min(8, boosts * 2) + Math.max(0, boosts - 4),
    err,
});

const calcModifierFromScore = ({ score }) => Math.floor((score - 10) / 2);

const strBoosts = levelBoundBoost('str');
const dexBoosts = levelBoundBoost('dex');
const conBoosts = levelBoundBoost('con');
const intBoosts = levelBoundBoost('int');
const wisBoosts = levelBoundBoost('wis');
const chaBoosts = levelBoundBoost('cha');

const strScore = createSelector(strBoosts, calcScoreFromBoost);
const dexScore = createSelector(dexBoosts, calcScoreFromBoost);
const conScore = createSelector(conBoosts, calcScoreFromBoost);
const intScore = createSelector(intBoosts, calcScoreFromBoost);
const wisScore = createSelector(wisBoosts, calcScoreFromBoost);
const chaScore = createSelector(chaBoosts, calcScoreFromBoost);

const scoreArray = createSelector(
    [strScore, dexScore, conScore, intScore, wisScore, chaScore],
    (str, dex, con, int, wis, cha) => [str, dex, con, int, wis, cha]
);

const strModifier = createSelector(strScore, calcModifierFromScore);
const dexModifier = createSelector(dexScore, calcModifierFromScore);
const conModifier = createSelector(conScore, calcModifierFromScore);
const intModifier = createSelector(intScore, calcModifierFromScore);
const wisModifier = createSelector(wisScore, calcModifierFromScore);
const chaModifier = createSelector(chaScore, calcModifierFromScore);

const modifierArray = createSelector(
    [
        strModifier,
        dexModifier,
        conModifier,
        intModifier,
        wisModifier,
        chaModifier,
    ],
    (str, dex, con, int, wis, cha) => [str, dex, con, int, wis, cha]
);

export const abilitySelector = {
    keyAbility: selectKeyAbilityBoost,
    ancestry: selectAncestryBoost,
    ancestryFlaw: selectAncestryFlaw,
    background: selectBackgroundBoost,
    level1: selectLevel1Boost,
    levelX: selectLevelXBoosts,
    strBoosts,
    dexBoosts,
    conBoosts,
    intBoosts,
    wisBoosts,
    chaBoosts,
    strScore,
    dexScore,
    conScore,
    intScore,
    wisScore,
    chaScore,
    scoreArray,
    strModifier,
    dexModifier,
    conModifier,
    intModifier,
    wisModifier,
    chaModifier,
    modifierArray,
};
