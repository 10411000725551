import { createSlice } from '@reduxjs/toolkit';
import { numUtil } from '../../util/numUtil';
import { sheetActions } from '../sheet/sheetActions';

const initialState = {
    current: 0,
    temp: 0,
    resistance: [],
    weakness: [],
    immunity: [],
    ancestry: 6,
    perLevel: 0,
};

const hpSlice = createSlice({
    name: 'hp',
    initialState,
    reducers: {
        current: (state, action) => {
            const currVal = numUtil.sane(action.payload);

            state.current = Math.max(0, currVal);
        },
        harm: (state, action) => {
            const lostVal = numUtil.sane(action.payload);
            const tempLost = state.temp - Math.abs(lostVal);
            state.temp = Math.max(0, tempLost);
            if (tempLost < 0)
                state.current = Math.max(0, state.current - Math.abs(tempLost));
        },
        heal: (state, action) => {
            const healVal = numUtil.sane(action.payload);
            state.current = Math.max(0, state.current + Math.abs(healVal));
        },
        temp: (state, action) => {
            state.temp = action.payload;
        },
        ancestry: (state, action) => {
            state.ancestry = action.payload;
        },
        perLevel: (state, action) => {
            state.perLevel = action.payload;
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING HP', action.payload.sheet.hp);

            return action.payload.sheet.hp;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
    },
});

export const hpAction = hpSlice.actions;
export const hpReducer = hpSlice.reducer;
