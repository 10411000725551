import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import { rpgUtil } from '../../util/rpgUtil';
import { saveSelector } from './saveSelector';
import { saveAction } from './saveSlice';

export const SaveLevel = () => {
    const saves = useSelector(saveSelector.saves);

    return (
        <div className='defense-level'>
            <div className='class-defense'>
                <h2>Proficency from Class</h2>
                <p>
                    Your class's saving throw proficiencies are automatically
                    loaded when selecting your class. You should not need to
                    edit them unless you are a monk or consulted with the GM.
                </p>

                <table>
                    <thead className='perception'>
                        <tr>
                            <th>Perception</th>
                            <th>Trained</th>
                            <th>Expert</th>
                            <th>Master</th>
                            <th>Legendary</th>
                        </tr>
                    </thead>

                    <tbody className='perception'>
                        <SaveTableRow
                            id='perception'
                            proficiency={saves.percClassLevel}
                        />
                    </tbody>

                    <thead className='save'>
                        <tr>
                            <th>Save</th>
                            <th>Trained</th>
                            <th>Expert</th>
                            <th>Master</th>
                            <th>Legendary</th>
                        </tr>
                    </thead>

                    <tbody className='save'>
                        <SaveTableRow
                            id='fortitude'
                            proficiency={saves.fortClassLevel}
                        />
                        <SaveTableRow
                            id='reflex'
                            proficiency={saves.refClassLevel}
                        />
                        <SaveTableRow
                            id='will'
                            proficiency={saves.willClassLevel}
                        />
                    </tbody>
                </table>
            </div>
            <CustomBonus />
        </div>
    );
};

const SaveTableRow = ({ id, proficiency }) => {
    const dispatch = useDispatch();
    let action;
    switch (id) {
        case 'fortitude':
            action = saveAction.fortClassLevel;
            break;
        case 'reflex':
            action = saveAction.refClassLevel;
            break;
        case 'will':
            action = saveAction.willClassLevel;
            break;
        case 'perception':
        default:
            action = saveAction.percClassLevel;
            break;
    }

    const [trainedLevel, expertLevel, masterLevel, legendaryLevel] =
        proficiency || [];

    const handleTrainedChange = (newLevel) =>
        dispatch(action([newLevel, expertLevel, masterLevel, legendaryLevel]));

    const handleExpertChange = (newLevel) =>
        dispatch(action([trainedLevel, newLevel, masterLevel, legendaryLevel]));

    const handleMasterChange = (newLevel) =>
        dispatch(action([trainedLevel, expertLevel, newLevel, legendaryLevel]));

    const handleLegendaryChange = (newLevel) =>
        dispatch(action([trainedLevel, expertLevel, masterLevel, newLevel]));

    return (
        <tr>
            <td>{capitalize(id)}</td>
            <td>
                <LevelSelect
                    value={trainedLevel}
                    onChange={handleTrainedChange}
                />
            </td>
            <td>
                <LevelSelect
                    value={expertLevel}
                    onChange={handleExpertChange}
                />
            </td>
            <td>
                <LevelSelect
                    value={masterLevel}
                    onChange={handleMasterChange}
                />
            </td>
            <td>
                <LevelSelect
                    value={legendaryLevel}
                    onChange={handleLegendaryChange}
                />
            </td>
        </tr>
    );
};

const LevelSelect = ({ value, onChange }) => {
    const handleChange = (e) =>
        typeof onChange === 'function' && onChange(e.target.value);

    return (
        <input
            value={Number(value) === 0 ? '–' : value}
            onChange={handleChange}
            onFocus={(event) => event.target.select()}
        />
    );
};

const CustomBonus = () => {
    const saves = useSelector(saveSelector.saves);

    const dispatch = useDispatch();

    const handleAddExtra = () =>
        dispatch(
            saveAction.add({
                save: { id: '', level: 1, rank: 2, type: 'extra' },
            })
        );

    return (
        <div className='extra-defense'>
            <h2>Extra Saves</h2>
            <p>
                You can can extra saves by taking the Canny Accumen feat or from
                other sources. Record them here.
            </p>
            <div className='extra-defense-control'>
                <button onClick={handleAddExtra}>
                    Add Extra Save Proficency
                </button>
            </div>
            <div className='defense-level-row'>
                {Object.values(saves.extra).map((eSave, i) => (
                    <ExtraSave key={i} save={eSave} />
                ))}
            </div>
        </div>
    );
};

const ExtraSave = ({ save }) => {
    const dispatch = useDispatch();
    const slotId = `save|${save.extraId}`;

    const handleLevelSelect = (e) =>
        dispatch(
            saveAction.add({
                save: {
                    ...save,
                    level: e.target.value || 1,
                },
            })
        );

    const handleSaveSelect = (e) => {
        if (e.target.value)
            dispatch(
                saveAction.add({
                    save: {
                        ...save,
                        id: e.target.value,
                    },
                })
            );
        else dispatch(saveAction.remove(slotId));
    };

    const handleRankSelect = (e) =>
        dispatch(
            saveAction.add({
                save: {
                    ...save,
                    rank: e.target.value,
                },
            })
        );
    const handleRemove = () => {
        dispatch(saveAction.remove(slotId));
    };

    return (
        <div className='rank-cell extra'>
            <label>Extra</label>
            <div className='split'>
                <select value={save.level} onChange={handleLevelSelect}>
                    {arrayUtil.arrayOfInt().map((lvl) => (
                        <option key={lvl} value={lvl}>
                            Level {lvl}
                        </option>
                    ))}
                </select>
                <button onClick={handleRemove}>
                    <FontAwesomeIcon icon='fa-solid fa-trash-can' />
                </button>
            </div>
            <select
                id={slotId}
                value={save?.id || ''}
                onChange={handleSaveSelect}
            >
                <option value={''}>none</option>
                {rpgUtil.saves.map((id) => (
                    <option key={id} value={id}>
                        {id}
                    </option>
                ))}
            </select>
            <select
                value={save?.rank || 0}
                onChange={handleRankSelect}
                disabled={!save?.id}
            >
                <option value={2}>expert</option>
                <option value={3}>master</option>
                <option value={4}>legendary</option>
            </select>
        </div>
    );
};
