import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import { rpgUtil } from '../../util/rpgUtil';
import { abilitySelector } from '../ability';
import { characterSelector } from '../character';
import './skill.css';
import { skillSelector } from './skillSelector';
import { skillAction } from './skillSlice';

export const SkillLevel = (props) => {
    const ranks = useSelector(skillSelector.ranks);
    const errors = useSelector(skillSelector.errors);
    const skills = useSelector(skillSelector.skills);
    const classSkillCount = useSelector(skillSelector.classSkillCount);
    const levelSkillCount = useSelector(skillSelector.levelSkillCount);
    const currentLevel = useSelector(characterSelector.level);
    const currentIntMod = useSelector(abilitySelector.intModifier);

    const dispatch = useDispatch();

    const predictRank = (skillId, level) => {
        let expectedRank = 0;

        if (skillId && ranks[skillId] && level) {
            expectedRank = rpgUtil.maxSkillRankByLevel(
                ranks[skillId][level] + 1,
                level
            );
            console.trace(
                `given '${skillId}' and level ${level}, expect next rank to be ${expectedRank}`
            );
        }

        return expectedRank;
    };

    const skillPack = {
        dispatch,
        skills,
        predictRank,
        errors,
        currentLevel,
        currentIntMod,
    };

    const extraSkills = Object.entries(skills)
        .filter(([_, skill]) => skill.type === 'extra')
        .map(([_, skill]) => skill);

    const loreSkills = Object.entries(skills)
        .filter(([_, skill]) => skill.type === 'lore')
        .map(([_, skill]) => skill);

    const handleAddExtra = () => {
        dispatch(
            skillAction.add({
                skill: {
                    id: '',
                    level: 1,
                    rank: 1,
                    type: 'extra',
                },
            })
        );
    };

    const handleAddLore = () => {
        dispatch(
            skillAction.add({
                skill: { id: '', level: 1, rank: 5, type: 'lore' },
            })
        );
    };

    return (
        <div className='skill-level'>
            <div className='class-skills'>
                <h2>Background & Class Skills</h2>
                <p>
                    Your background gives you trained proficiency with
                    1&nbsp;skill (the lore skill goes below).
                    <br />
                    Your class gives you a trained proficiency with{' '}
                    {classSkillCount}&nbsp;skills.
                </p>
                <div className='skill-level-row'>
                    <ClassSkill type='background' {...skillPack} index={0} />
                    {arrayUtil.arrayOfInt(classSkillCount).map((index) => (
                        <ClassSkill key={index} {...skillPack} index={index} />
                    ))}
                </div>
            </div>
            <div className='int-skills'>
                <h2>Int Skills</h2>
                <p>
                    You gain trained proficiency with a number of skills equal
                    to your Intelligence modifier. Grayed-out skills will be
                    added to your sheet if/when you reach that Intelligence
                    value.
                </p>
                <div className='skill-level-row'>
                    <IntSkill {...skillPack} intMod={1} />
                    <IntSkill {...skillPack} intMod={2} />
                    <IntSkill {...skillPack} intMod={3} />
                    <IntSkill {...skillPack} intMod={4} />
                    <IntSkill {...skillPack} intMod={5} />
                    <IntSkill {...skillPack} intMod={6} />
                </div>
            </div>
            <div className='level-skills'>
                <h2>Level Skills</h2>
                <p>
                    You gain proficiency with a new skill or increase the
                    proficiency of skills every odd level.
                </p>
                <div className='skill-level-row'>
                    {levelSkillCount.map((level) => (
                        <LevelSkill key={level} {...skillPack} level={level} />
                    ))}
                </div>
            </div>
            <div className='extra-skills'>
                <h2>Lores & Extra Skills</h2>
                <p>
                    Lores must be spelled and capitalized exactly the same to
                    combine ranks.
                </p>
                <div className='extra-skill-control'>
                    <button onClick={handleAddLore}>Add Lore</button>
                    <button onClick={handleAddExtra}>Add Extra Skill</button>
                </div>
                <div className='skill-level-row'>
                    {loreSkills.map((lore, i) => (
                        <ExtraSkill
                            type='lore'
                            key={'lore' + i}
                            {...skillPack}
                            skill={lore}
                        />
                    ))}
                    {extraSkills.map((eSkill, i) => (
                        <ExtraSkill
                            key={'extra' + i}
                            {...skillPack}
                            skill={eSkill}
                        />
                    ))}
                </div>
            </div>
            <hr />
            <p>
                Track and manage your proficiency with skills and lores. This
                will manage the number of skill proficiencies and increases, but
                does not automatically fill anything in. Be sure to select any
                predetermined skills from various sources.
            </p>
            <p>Any slot with a red background has an error. The errors are:</p>
            <ul>
                <li>
                    You gain the same proficiency rank from multiple sources.
                </li>
                <li>You are have a lower rank appearing after a higher one.</li>
                <li>
                    You are missing the previous proficiency rank. (Extra skills
                    and lores ignore this restriction.)
                </li>
            </ul>
        </div>
    );
};

const ClassSkill = ({ type = 'class', index, skills, errors, dispatch }) => {
    const slotId = `${type}|${index}`;
    const skill = skills[slotId];
    const hasError =
        skill &&
        errors.some((err) => err.id === skill.id && err.rank === skill.rank);
    const handleSelect = (e) => {
        if (e.target.value)
            dispatch(
                skillAction.add({
                    slotId,
                    skill: { id: e.target.value, level: 1, rank: 1, type },
                })
            );
        else dispatch(skillAction.remove(slotId));
    };

    return (
        <div className={`rank-cell class ${hasError ? 'error' : ''}`}>
            <label htmlFor={slotId}>{capitalize(type)} — trained</label>
            <select id={slotId} value={skill?.id || ''} onChange={handleSelect}>
                <option value={''}>none</option>
                {rpgUtil.skills.map((id) => (
                    <option key={id} value={id}>
                        {id}
                    </option>
                ))}
            </select>
        </div>
    );
};

const IntSkill = ({
    type = 'int',
    intMod,
    skills,
    errors,
    dispatch,
    currentIntMod,
}) => {
    const slotId = `int|${intMod}`;
    const skill = skills[slotId];
    const hasError =
        skill &&
        errors.some((err) => err.id === skill.id && err.rank === skill.rank);

    const handleSelect = (e) => {
        if (e.target.value)
            dispatch(
                skillAction.add({
                    slotId,
                    skill: { id: e.target.value, intMod, rank: 1, type },
                })
            );
        else dispatch(skillAction.remove(slotId));
    };

    return (
        <div
            className={`rank-cell int ${hasError ? 'error' : ''} ${
                currentIntMod >= intMod ? 'active' : 'inactive'
            }`}
        >
            <label htmlFor=''>Int {intMod * 2 + 10} — trained</label>
            <select id={slotId} value={skill?.id || ''} onChange={handleSelect}>
                <option value={''}>none</option>
                {rpgUtil.skills.map((id) => (
                    <option key={id} value={id}>
                        {id}
                    </option>
                ))}
            </select>
        </div>
    );
};

const LevelSkill = ({
    type = 'level',
    level,
    skills,
    predictRank,
    errors,
    dispatch,
    currentLevel,
}) => {
    const slotId = `${type}|${level}`;
    const skill = skills[slotId];
    const hasError =
        skill &&
        errors.some((err) => err.id + err.rank === skill.id + skill.rank);

    const handleSkillSelect = (e) => {
        if (e.target.value)
            dispatch(
                skillAction.add({
                    slotId,
                    skill: {
                        id: e.target.value,
                        level,
                        rank:
                            (typeof predictRank === 'function'
                                ? predictRank(e.target.value, level)
                                : 0) ||
                            skill?.rank ||
                            1,
                        type,
                    },
                })
            );
        else dispatch(skillAction.remove(slotId));
    };

    const handleRankSelect = (e) =>
        dispatch(
            skillAction.add({
                slotId,
                skill: { id: skill.id, level, rank: e.target.value || 1, type },
            })
        );
    return (
        <div
            className={`rank-cell level ${hasError ? 'error' : ''}  ${
                currentLevel >= level ? 'active' : 'inactive'
            }`}
        >
            <label htmlFor={slotId}>Level {level}</label>
            <select
                id={slotId}
                value={skill?.id || ''}
                onChange={handleSkillSelect}
            >
                <option value={''}>none</option>
                {rpgUtil.skills.map((id) => (
                    <option key={id} value={id}>
                        {id}
                    </option>
                ))}
            </select>
            <select
                value={skill?.rank}
                onChange={handleRankSelect}
                disabled={!skill?.id}
            >
                <option value={1}>trained</option>
                <option value={2}>expert</option>
                <option value={3} disabled={level < 7}>
                    master
                </option>
                <option value={4} disabled={level < 15}>
                    legendary
                </option>
            </select>
        </div>
    );
};

const ExtraSkill = ({
    type = 'extra',
    skill,
    predictRank,
    errors,
    dispatch,
    currentLevel,
}) => {
    const slotId = `${type}|${skill.extraId}`;
    const hasError =
        skill &&
        errors.some((err) => err.id + err.rank === skill.id + skill.rank);

    const handleLevelSelect = (e) => {
        dispatch(
            skillAction.add({
                skill: {
                    ...skill,
                    level: e.target.value || 1,
                    rank:
                        (typeof predictRank === 'function'
                            ? predictRank(skill.id, e.target.value)
                            : 0) ||
                        skill?.rank ||
                        1,
                },
            })
        );
    };

    const handleSkillSelect = (e) => {
        if (e.target.value)
            dispatch(
                skillAction.add({
                    skill: {
                        ...skill,
                        id: e.target.value,
                        rank:
                            (typeof predictRank === 'function'
                                ? predictRank(e.target.value, skill?.level)
                                : 0) ||
                            skill?.rank ||
                            1,
                    },
                })
            );
        else dispatch(skillAction.remove(slotId));
    };

    const handleRankSelect = (e) =>
        dispatch(
            skillAction.add({
                skill: {
                    ...skill,
                    rank: e.target.value || 1,
                },
            })
        );

    const handleRemove = () => {
        dispatch(skillAction.remove(slotId));
    };

    let skillIdElem = (
        <select
            id={slotId}
            value={skill?.id || ''}
            onChange={handleSkillSelect}
        >
            <option value={''}>none</option>
            {rpgUtil.skills.map((id) => (
                <option key={id} value={id}>
                    {id}
                </option>
            ))}
        </select>
    );

    if (type === 'lore')
        skillIdElem = (
            <input
                id={slotId}
                value={skill?.id || ''}
                onChange={handleSkillSelect}
                onFocus={(event) => event.target.select()}
            />
        );

    return (
        <div
            className={`rank-cell extra ${hasError ? 'error' : ''} ${
                currentLevel >= skill?.level ? 'active' : 'inactive'
            }`}
        >
            <label htmlFor={slotId}>{capitalize(type)}</label>
            <div className='split'>
                <select value={skill.level} onChange={handleLevelSelect}>
                    {arrayUtil.arrayOfInt().map((lvl) => (
                        <option key={lvl} value={lvl}>
                            Level {lvl}
                        </option>
                    ))}
                </select>
                <button onClick={handleRemove}>
                    <FontAwesomeIcon icon='fa-solid fa-trash-can' />
                </button>
            </div>
            {skillIdElem}
            <select
                value={skill?.rank || 0}
                onChange={handleRankSelect}
                disabled={!skill?.id}
            >
                <option value={1}>trained</option>
                <option value={2}>expert</option>
                <option value={3}>master</option>
                <option value={4}>legendary</option>
                <option value={5}>auto-scaling</option>
            </select>
        </div>
    );
};
