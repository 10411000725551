import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { RankRow } from '../common';

import { saveSelector } from './index';

import './save.css';
import '../../css/defense-tab.css'
import { SaveLevel } from './SaveLevel';

export const Save = (props) => {
    const finalValues = useSelector(saveSelector.finalValues);

    return (
        <div className='save'>
            <EditModal />
            <div className='rank-table'>
                <div className='rank-row header'>
                    <span><FontAwesomeIcon icon='fa-solid fa-eye' />Perception</span>
                    <span>Final</span>
                    <span></span>
                    <span>Prof</span>
                    <span></span>
                    <span>Mod</span>
                    <span></span>
                    <span>Pot</span>
                </div>
                <RankRow
                    type='save'
                    id='perception'
                    value={finalValues.perception}
                />
            </div>

            <div className='rank-table'>
                <div className='rank-row header'>
                    <span><FontAwesomeIcon icon='fa-solid fa-shield-halved' />Save</span>
                    <span>Final</span>
                    <span></span>
                    <span>Prof</span>
                    <span></span>
                    <span>Mod</span>
                    <span></span>
                    <span>Pot</span>
                </div>
                <RankRow
                    type='save'
                    id='fortitude'
                    value={finalValues.fortitude}
                />
                <RankRow type='save' id='reflex' value={finalValues.reflex} />
                <RankRow type='save' id='will' value={finalValues.will} />
            </div>
        </div>
    );
};

const EditModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className='defense-edit'>
            <button onClick={openModal}>
                <FontAwesomeIcon icon='fa-solid fa-pen-to-square' /> Edit Perception & Saves
            </button>
            <Modal
                className='modal-modal'
                overlayClassName='modal-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <SaveLevel />
            </Modal>
        </div>
    );
};
