import { createSlice } from '@reduxjs/toolkit';
import { stringUtil } from '../../util/stringUtil';
import { sheetActions } from '../sheet/sheetActions';

const initialState = stringUtil.uuidv4();

const uuidSlice = createSlice({
    name: 'uuid',
    initialState,
    reducers: {
        generateNewUUID: (state) => {
            state = stringUtil.uuidv4();
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            return action.payload.uuid;
        },
        [sheetActions.reset]: () => {
            return stringUtil.uuidv4();
        },
    },
});

export const uuidAction = uuidSlice.actions;
export const uuidReducer = uuidSlice.reducer;
