import '../../css/rank-table.css';

const Prof = ({ label, value }) => {
    return (
        <span className='rank-prof'>
            <span>
                <label htmlFor={label + 'T'}>T</label>
                <input
                    id={label + 'T'}
                    type='checkbox'
                    checked={value >= 2}
                    readOnly
                />
            </span>
            <span>
                <label htmlFor={label + 'E'}>E</label>
                <input
                    id={label + 'E'}
                    type='checkbox'
                    checked={value >= 4}
                    readOnly
                />
            </span>
            <span>
                <label htmlFor={label + 'M'}>M</label>
                <input
                    id={label + 'M'}
                    type='checkbox'
                    checked={value >= 6}
                    readOnly
                />
            </span>
            <span>
                <label htmlFor={label + 'L'}>L</label>
                <input
                    id={label + 'L'}
                    type='checkbox'
                    checked={value >= 8}
                    readOnly
                />
            </span>
        </span>
    );
};

export default Prof;
