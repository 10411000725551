import { createSlice } from '@reduxjs/toolkit';
import { stringUtil } from '../../util/stringUtil';
import { characterAction } from '../character';
import { sheetActions } from '../sheet/sheetActions';

const initialState = {};

const skillSlice = createSlice({
    name: 'skill',
    initialState,
    reducers: {
        add: (state, action) => {
            const skill = action.payload.skill;
            if (!skill.slotId && !skill.extraId)
                skill.extraId = stringUtil.uuidv4();

            const slotId =
                action.payload.slotId || `${skill.type}|${skill.extraId || 0}`;
            state[slotId] = skill;
        },
        remove: (state, action) => {
            delete state[action.payload];
        },
    },
    extraReducers: {
        [sheetActions.loadSheet]: (state, action) => {
            console.log('LOADING SKILL', action.payload.sheet.skill);

            return action.payload.sheet.skill;
        },
        [sheetActions.reset]: () => {
            return initialState;
        },
        [characterAction.class]: (state, action) => {
            Object.entries(state)
                .filter(
                    ([_, slot]) =>
                        slot.type === 'class' ||
                        (slot.type === 'level' && slot.level % 2 === 0)
                )
                .forEach(([slotId]) => {
                    delete state[slotId];
                });
        },
    },
});

export const skillAction = skillSlice.actions;
export const skillReducer = skillSlice.reducer;
