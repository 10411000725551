/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '@ramonak/react-progress-bar';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { isNumber } from 'lodash';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUtil } from '../../util/arrayUtil';
import './hp.css';
import { hpSelector } from './hpSelector';
import { hpAction } from './hpSlice';

const checkToast = (oldVal, newVal, str = 'HP') => {
    oldVal = Number(oldVal);
    newVal = Number(newVal);
    const diff = newVal - oldVal;
    if (diff && isNumber(newVal)) {
        if (diff < 0) toast(`–${Math.abs(diff)} ${str}`);
        else toast(`+${Math.abs(diff)} ${str}`);
    }
};

export const HP = (props) => {
    const currentHP = useSelector(hpSelector.current);
    const tempHP = useSelector(hpSelector.temp) || 0;
    const maxHP = useSelector(hpSelector.hpTotal);

    const dispatch = useDispatch();

    const [hpToast, setHPToast] = useState();
    const [tempToast, setTempToast] = useState();

    useEffect(() => {
        checkToast(hpToast, currentHP);
        setHPToast(currentHP);
    }, [currentHP]);

    useEffect(() => {
        checkToast(tempToast, tempHP, 'TEMP HP');
        setTempToast(tempHP);
    }, [tempHP]);

    const percent = Math.ceil((currentHP / maxHP) * 100);

    return (
        <div className='hp'>
            <span className='tab-heading'>
                <EditModal />
                <h2>HP</h2>
            </span>
            <div className='hp-display'>
                <div className='hp-label'>
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
                    {`${currentHP} / ${maxHP}`}
                    {tempHP ? (
                        <span>
                            <FontAwesomeIcon icon='fa-solid fa-shield-heart' />
                            {`${tempHP}`}
                        </span>
                    ) : null}
                </div>
                <ProgressBar
                    className='hp-bar'
                    completed={currentHP}
                    maxCompleted={maxHP}
                    bgColor='#650d1b'
                    // labelAlignment='left'
                    isLabelVisible={Boolean(percent > 20)}
                    customLabel={percent + '%'}
                />
            </div>

            <div className='hp-control'>
                <LostModal
                    label='Harm'
                    initialState='-'
                    buttonLabel='HARM'
                    buttonIcon='fa-heart-circle-minus'
                />
                <LostModal
                    label='Heal'
                    initialState='+'
                    buttonLabel='HEAL'
                    buttonIcon='fa-heart-circle-plus'
                />
                <LostModal
                    label='Set Current'
                    initialState=''
                    buttonLabel='SET'
                    buttonIcon='fa-heart-circle-check'
                />
                <LostModal
                    label='Temp HP'
                    initialState=''
                    buttonLabel='TEMP'
                    buttonIcon='fa-heart-circle-bolt'
                    customSubmit={(dispatch, newValue) =>
                        dispatch(hpAction.temp(newValue))
                    }
                />
            </div>
            <div className='hp-control'>
                <button onClick={() => dispatch(hpAction.current(maxHP))}>
                    <FontAwesomeIcon icon='fa-solid fa-heart' /> Full Heal{' '}
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
                </button>
            </div>
        </div>
    );
};

const EditModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className='hp-edit'>
            <button onClick={openModal}>
                <FontAwesomeIcon icon='fa-solid fa-pen-to-square' />
            </button>
            <Modal
                className='modal-modal'
                overlayClassName='modal-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <Edit />
            </Modal>
        </div>
    );
};

const Edit = () => {
    const { total, ancestry, classPerLevel, bonusPerLevel, conMod, level } =
        useSelector(hpSelector.formula);
    const dispatch = useDispatch();

    return (
        <div>
            <h1>Edit HP Total</h1>
            <div className='hp-formula'>
                <span>{total}</span>
                <span>=</span>
                <span>
                    {ancestry}
                    <sub>ancestry</sub>
                </span>
                <span>{'+ ('}</span>
                <span>
                    {classPerLevel}
                    <sub>class</sub>
                </span>
                <span>{'+'}</span>
                <span>
                    {conMod}
                    <sub>con mod</sub>
                </span>
                <span>{'+'}</span>
                <span>
                    {bonusPerLevel}
                    <sub>bonus</sub>
                </span>
                <span>{' ) ×'}</span>
                <span>
                    {level}
                    <sub>level</sub>
                </span>
            </div>
            <div className='hp-choice'>
                <div>
                    <label htmlFor='ancestryInput'>Ancestry HP</label>
                    <select
                        className='ancestry'
                        name='ancestryInput'
                        value={ancestry}
                        onChange={(e) => {
                            dispatch(hpAction.ancestry(e.target.value));
                        }}
                    >
                        {arrayUtil.arrayOfInt(16).map((hp) => (
                            <option key={hp} value={hp}>
                                {hp}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor='bonusInput'>Bonus HP per Level</label>
                    <input
                        className='bonus'
                        name='bonusInput'
                        value={bonusPerLevel}
                        onChange={(e) => {
                            dispatch(hpAction.perLevel(e.target.value));
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const LostModal = ({
    label = 'Current HP',
    initialState = '-',
    buttonLabel = '',
    buttonIcon = 'fa-heart-circle-minus',
    customSubmit,
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(initialState);
    const maxHP = useSelector(hpSelector.hpTotal);
    const dispatch = useDispatch();

    const openModal = (e) => {
        if (e) e.preventDefault();
        setIsOpen(true);
        setValue(initialState);
    };
    const closeModal = () => setIsOpen(false);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleSubmit = () => {
        if (typeof customSubmit === 'function') {
            customSubmit(dispatch, value);
            closeModal();
            return;
        }
        const strVal = String(value);
        if (strVal) {
            if (strVal.startsWith('+')) dispatch(hpAction.heal(Number(value)));
            else if (strVal.startsWith('-'))
                dispatch(hpAction.harm(Number(value)));
            else dispatch(hpAction.current(Math.min(maxHP, Number(value))));
            closeModal();
        }
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className='hp-lost-edit'>
            <button
                onClick={openModal}
                id={buttonIcon + '-tooltip'}
                data-tooltip-content={buttonLabel}
            >
                <FontAwesomeIcon icon={'fa-solid ' + buttonIcon} />
                <span>{buttonLabel}</span>
            </button>
            {/* <Tooltip anchorId={buttonIcon + '-tooltip'} /> */}
            <Modal
                className='modal-modal hp-lost-modal'
                overlayClassName='modal-overlay hp-lost-overlay'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <h1>{label}</h1>
                <input
                    onChange={handleChange}
                    onKeyDown={handleEnter}
                    value={value}
                    autoFocus
                />

                <button onClick={handleSubmit}>SUBMIT</button>
            </Modal>
        </div>
    );
};
